import React from 'react';
import { LoginDispacher, UserAppReportPair } from '../Context/AuthProvider/AuthDomain';
import { addSubReports, setSubReports } from '../Context/AuthProvider/AuthProviderActions';

/**
 * Helper functions for interfacing with Visualizer.js global instance.
 */

export interface Report {
    container: string;
    resource: string;
}

class VisualizeHelper {
    /**
     * Type definitions do not yet exist for Visualize.js. Casting as any, for now.
     */
    public viz = (window as any).visualize;
    private hasConfig = false;

    hasLogin() {
        return this.hasConfig;
    }

    /**
     * Token-Based Authentication Login
     * @param userToken
     * @param jaspersoftServerUrl
     */
    login(userName: string, userPass: string) {
        const jaspersoftServerUrl = '/jasperserver-pro';
        this.viz.config({
            server: jaspersoftServerUrl,
            scripts: 'optimized-scripts',
            theme: {
                href: `${ jaspersoftServerUrl }/themes/default`
            },
            auth: {
                name: userName,
                password: userPass
            },
            require: {
                urlArgs: 'bust=' + (new Date()).getTime()
            }
        });

        setTimeout(()=>{ this.hasConfig = true; }, 500);
    }

    /**
     * Token-Based Authentication Logout
     */
    logOut() {
        this.viz((v: any) => {
            return async () => {
                await v.logout();
            };
        });

        setTimeout(()=>{ this.hasConfig = false; }, 500);
    }

    /**
     * Get report
     * @param uiContainer
     * @param resourcePath
     * @param params
     * @param linkOptions
     */
    getReport(
        dispatch:React.Dispatch<LoginDispacher>,
        uiContainer: string,
        resourcePath: string,
        setReportData: React.Dispatch<any>,
        setCurrentPage: React.Dispatch<number>,
        setNumberOfPages: React.Dispatch<number>,
        ignorePagination = false,
        params: any = {},
        _linkOptions: any = {}
    ) {
        setSubReports(resourcePath, [], dispatch);
        return new Promise((resolve, reject) => {
            this.viz((v: any) => {
                const report = v.report({
                    container: `#${uiContainer}`,
                    resource: resourcePath,
                    params,
                    linkOptions: {
                        beforeRender (linkToElemPairs) {
                            linkToElemPairs.forEach(pair =>{ showCursor(pair, resourcePath, uiContainer, dispatch); });
                        }
                    },
                    scrollToTop: true,
                    loadingOverlay: true,
                    ignorePagination,
                    success: (success: any) => {
                        resolve(success);
                    },
                    error: (err: any) => {
                        reject(err);
                    },
                    events: {
                        changePagesState(page) {
                            setCurrentPage(page);
                        },
                        changeTotalPages(pages) {
                            setNumberOfPages(pages);
                        }
                    }
                });

                setReportData(report);
            });
        });
    }

    /**
     *
     * @param uiContainer
     * @param resourcePath
     * @param params
     */
    getAdHocView(
        uiContainer: string,
        resourcePath: string,
        setReportData: React.Dispatch<any>,
        params: any = {}
    ) {
        return new Promise((resolve, reject) => {
            this.viz((v: any) => {
                const report = v.adhocView({
                    container: `#${uiContainer}`,
                    resource: resourcePath,
                    params,
                    success: (success: any) => {
                        resolve(success);
                    },
                    error: (err: any) => {
                        console.log('getAdHocView', err);
                        reject(err);
                    }
                });

                setReportData(report);
            });
        });
    }

    /**
     * Get Input control
     * @param uiContainer
     * @param resourcePath
     * @param params
     */
    getInputControl(
        uiContainer: string | null,
        resourcePath: string,
        params: any = {}
    ) {
        return new Promise((resolve, reject) => {
            this.viz((v: any) => {
                v.inputControls({
                    //container: (uiContainer !== null) ? "#" + uiContainer : null,
                    resource: resourcePath,
                    params,
                    success: (success: any) => {
                        resolve(success);
                    },
                    error: (err: any) => {
                        console.log('getReport', err);
                        reject(err);
                    }
                });
            });
        });
    }

    /**
     * Get list of reports in repo folder
     * @param folderUrl
     * @param reportTypes
     */
    getReportList(folderUrl: string, _reportTypes: any) {
        return new Promise((resolve, reject) => {
            this.viz((v: any) => {
                v.resourcesSearch({
                    folderUri: folderUrl,
                    recursive: true,
                    // types: [reportTypes],
                    success(repo: any) {
                        resolve(repo);
                    },
                    error(err: any) {
                        reject(err);
                    }
                });
            });
        });
    }

    getInfo() {
        console.log(this);
    }
}

function showCursor(pair, uri:string, uiContainer:string, dispatch:React.Dispatch<LoginDispacher>) {
    const el = pair.element;
    const data = pair.data as UserAppReportPair;

    const params = {};
    for (const index in data.parameters) {
        const value = data.parameters[ index ];
        params[index] = [ value ];
    }

    data.parameters = params;
    el.addEventListener('click', ()=>{
        addSubReports(uri, data, dispatch);
        const elementsURI = uri.split('/');

        const url = `/sub-report/${ elementsURI[ elementsURI.length - 1 ] }/${ data.id }`;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) {newWindow.opener = null;}
    });
    el.style.cursor = 'pointer';
}

export const visualizeHelper = new VisualizeHelper();
