import React from 'react';
import { Login } from '../Pages/Login/Login';
import { Dashboard } from '../Pages/Dashboard/Dashboard';
import { NotFound } from '../Pages/NotFound/NotFound';
import { TermsOfUse } from '../Pages/InfoPages/TermsOfUse';
import { PrivacyPolicies } from '../Pages/InfoPages/PrivacyPolicies';
import { Report } from '../Pages/Report/Report';
import { SubReport } from '../Pages/Report/SubReport';

export interface RoutesType {
    path: string;
    component: React.ComponentType;
    isPrivate: boolean
}

export const routes:RoutesType[] = [
    {
        path: '/login',
        component: Login,
        isPrivate: false
    },
    {
        path: '/',
        component: Dashboard,
        isPrivate: true
    },
    {
        path: '/report/:id',
        component: Report,
        isPrivate: true
    },
    {
        path: '/sub-report/:id/:subId',
        component: SubReport,
        isPrivate: true
    },
    {
        path: '/terms-of-use',
        component: TermsOfUse,
        isPrivate: false
    },
    {
        path: '/privacy-policies',
        component: PrivacyPolicies,
        isPrivate: false
    },
    {
        path: '/*',
        component: NotFound,
        isPrivate: false
    }
];
