import React from 'react';
import { DiazarLoginForm } from './DiazarLoginForm';
import { LoginLayout } from '../../Layouts/LoginLayout';
import { useCookies } from 'react-cookie';
import { APP_ID, COOKIE_NAME, ServicesUrl } from '../../Config/UrlRuotes';
import { DiazarSuiteTokenModal } from './DiazarSuiteTokenModal';
import { useAuthDispatch } from '../../Context/AuthProvider/AuthProvider';
import { useAlertDispatcherState } from '../../Context/AlertProvider/AlertProvider';
import { useNavigate } from 'react-router-dom';
import { validateToken } from '../../Context/AuthProvider/AuthProviderActions';
import { addAlert } from '../../Context/AlertProvider/AlertProviderActions';

export interface DiazarSuiteValidToken {
    company: string
    key: string
    name: string
    token: string
}

interface DiazarSuiteValidTokensResponse {
    error: {
        'has-error': boolean
        message?: string
    },
    response: DiazarSuiteValidToken[]
}

export const Login: React.FunctionComponent = (_props) => {
    const [ cookies, setCookie ] = useCookies([ COOKIE_NAME ]);
    const [ tokens, setTokens ] = React.useState<DiazarSuiteValidToken[]>([]);
    const [ useTokens, setUseTokens ] = React.useState<boolean>(false);

    const dispatch = useAuthDispatch();
    const alertDispatch = useAlertDispatcherState();
    const navigate = useNavigate();

    const textContent = React.useMemo(() => {
        const text = 'Te presentamos la forma más optima de obtener los indicadores de tu negocio almacenados ' +
            'en Diazar®. Si desea obtener más información comunícate con nosotros y revisa nuestros:';
        return text;
    }, []);

    React.useEffect(()=>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(cookies)
        };
        try {
            fetch(ServicesUrl.appsValidate, requestOptions)
            .then(response => response.json())
            .then((data : DiazarSuiteValidTokensResponse) => {
                if (data.error['has-error']) {
                    setUseTokens(false);
                } else {
                    setUseTokens(true);
                    setTokens(data.response);
                }
            });
        } catch (error) {
            const message = 'Error al momento de realizar la petición';
            console.log(message);
        }
    }, [ cookies ]);

    const addSessionCoockie = React.useCallback((token:string)=>{
        let cookieObj = (cookies[COOKIE_NAME] !== undefined) ? cookies[COOKIE_NAME] : [];
        cookieObj = cookieObj.filter(val => val.id !== APP_ID);
        cookieObj.push({ id: APP_ID, token });

        setCookie(COOKIE_NAME, cookieObj, { path: '/', domain: 'diazar.com' });
    }, [ cookies, setCookie ]);

    const handleSendModalToggle = React.useCallback(async (selectedItem:DiazarSuiteValidToken)=>{
        const responseLogin = await validateToken(selectedItem.token, dispatch);
        if (!responseLogin || !responseLogin.response || !responseLogin.response.user || responseLogin.response.user.id <= 0) {return;}

        addAlert('Autentificación exitosa', undefined, 'success', alertDispatch);
        addSessionCoockie(responseLogin.response.token);
        navigate('/');

    }, [ addSessionCoockie, alertDispatch, dispatch, navigate ]);

    return (
        <React.Fragment>

            <LoginLayout loginTitle="Iniciar Sesión"
                loginSubtitle="Inicie Sesión para poder acceder a sus reportes."
                textContent={ textContent }
            >
                <DiazarSuiteTokenModal isModalOpen={ useTokens }
                    setIsModalOpen={ setUseTokens }
                    items={ tokens }
                    onClickItem={ handleSendModalToggle }
                />
                <DiazarLoginForm />
            </LoginLayout>

        </React.Fragment>
    );
};
