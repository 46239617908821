import React from 'react';

import {
    LoginPage,
    LoginMainFooterBandItem,
    LoginMainFooterLinksItem
} from '@patternfly/react-core';

import { FacebookFIcon, TwitterIcon, YoutubeSquareIcon, LinkedinIcon } from '@patternfly/react-icons';
import { big_reverse_logo } from '../Config/Images';
import { ListItemsInfoLinks } from '../Components/ListItemsInfoLinks';
import { DiazarAlerts } from '../Components/DiazarAlerts';
import { DiazarBackgroundImage } from '../Components/DiazarBackgroundImage';

interface ViewProps{
    children?: React.ReactNode;
    textContent?: string;
    loginTitle: string;
    loginSubtitle?: string;
}

export const LoginLayout: React.FunctionComponent<ViewProps> = (props) => {

    const signUpForAccountMessage = React.useMemo(() => (
        <LoginMainFooterBandItem>
            Necesitas <a href="/login">Ayuda?</a>.
        </LoginMainFooterBandItem>
    ), []);

    const forgotCredentials = (
        <LoginMainFooterBandItem>
            <a href="https://controldeusuarios.diazar.com/" target="_blank" rel="noreferrer">
                Olvidaste tu contraseña?
            </a>
        </LoginMainFooterBandItem>
    );

    const socialMediaLoginContent = (
        <React.Fragment>
            <LoginMainFooterLinksItem href="https://www.facebook.com/DiazarControl/"
                linkComponentProps={ { 'aria-label': 'Facebook' } }
                target="_blank"
            >
                <FacebookFIcon />
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://twitter.com/diazarcontrol"
                linkComponentProps={ { 'aria-label': 'Twitter' } }
                target="_blank"
            >
                <TwitterIcon size="lg" />
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://www.youtube.com/user/DiazarNoticias"
                linkComponentProps={ { 'aria-label': 'Youtube' } }
                target="_blank"
            >
                <YoutubeSquareIcon size="lg" />
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://www.linkedin.com/in/diazar-control-5068323b"
                linkComponentProps={ { 'aria-label': 'Linkedin' } }
                target="_blank"
            >
                <LinkedinIcon size="lg"/>
            </LoginMainFooterLinksItem>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <DiazarBackgroundImage />
            <LoginPage
                brandImgAlt="Diazar Logo"
                brandImgSrc={ big_reverse_logo }
                backgroundImgAlt="BG Images"
                footerListItems={ <ListItemsInfoLinks /> }
                textContent={ props.textContent }
                loginTitle={ props.loginTitle }
                loginSubtitle={ props.loginSubtitle }
                signUpForAccountMessage={ signUpForAccountMessage }
                socialMediaLoginContent={ socialMediaLoginContent }
                forgotCredentials={ forgotCredentials }
            >
                <DiazarAlerts />
                { props.children }
            </LoginPage>

        </React.Fragment>
    );
};
