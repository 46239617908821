import React from 'react';
import { ReportInputControl } from '../../Context/AuthProvider/AuthDomain';
import {
    Form,
    FormGroup,
    TextInput,
    Modal,
    ModalVariant,
    Button,
    HelperText,
    HelperTextItem, FormHelperText
} from '@patternfly/react-core';
import { DiazarInputControlSelect } from './DiazarInputControlSelect';

interface ViewProps{
    setParamsResult: React.Dispatch<any>,
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    showReport: React.Dispatch<React.SetStateAction<number>>,
    modalParams: ReportInputControl[]
}

export const DiazarModalReportInputControls: React.FunctionComponent<ViewProps> = (props) => {
    const [ params, setParams ] = React.useState<any>({});
    const { setParamsResult, showModal, setShowModal, modalParams, showReport } = props;

    const closeModalToggle = React.useCallback(()=>{
        setShowModal(prevState => !prevState);
    }, [ setShowModal ]);

    const confirmModalToggle = React.useCallback(()=>{
        const paramsResult = {};
        modalParams.map(data => {

            if (typeof params[ data.id ] === 'string') {
                paramsResult[ data.id ] = [];
                paramsResult[ data.id ].push(params[ data.id ]);
            } else {
                paramsResult[data.id] = params[data.id];
            }

            return true;
        });

        setParamsResult(paramsResult);
        showReport(prevState => prevState + 1);
        setShowModal(prevState => !prevState);
    }, [ setShowModal, modalParams, params, setParamsResult, showReport ]);

    const resetModalToggle = React.useCallback(()=>{
        const paramsAux = {};
        modalParams.map(data => {
            paramsAux[ data.id ] = data.value;
            if (data.values && data.values.length > 0) {
                if (data.values.length === 1) {
                    if (typeof data.values[0] === 'string') {
                        paramsAux[ data.id ] = data.values[0];
                    } else {
                        paramsAux[ data.id ] = data.values[0].id;
                    }
                } else {
                    paramsAux[ data.id ] = data.values.map(val => val.id);
                }

            }

            return true;
        });

        setParams(paramsAux);
    }, [ modalParams ]);

    const onChangeInput = React.useCallback((id:string, inputVal:any)=>{
        const aux = { ...params };
        aux[ id ] = inputVal;

        setParams(aux);
    }, [ params ]);

    const FormElement = React.useMemo(()=>{
        return (
            <Form isHorizontal>
                {
                    modalParams.map(inputData => {
                        const value = (params[ inputData.id ]) ? params[ inputData.id ] : '';
                        const input = (inputData.values) ? (
                            <DiazarInputControlSelect inputData={ inputData }
                                selected={ value }
                                onChange={ onChangeInput }
                            />
                        ) : (
                            <TextInput
                                isRequired={ inputData.mandatory }
                                type={ inputData.dataType.type }
                                id={ inputData.id }
                                name={ inputData.id }
                                aria-describedby={ inputData.id }
                                value={ value }
                                onChange={ e => {
                                    onChangeInput(inputData.id, e);
                                } }
                            />
                        );
                        const helperText = (inputData.description && inputData.description !== '') ? (
                            <FormHelperText isHidden={ false } component="div">
                                <HelperText id={ `helper-text-${ inputData.id }` } aria-live="polite">
                                    {
                                        (
                                            <HelperTextItem >
                                                { inputData.description }
                                            </HelperTextItem>
                                        )
                                    }
                                </HelperText>
                            </FormHelperText>
                        ) : null;
                        return (
                            <FormGroup
                                key={ inputData.id }
                                label={ inputData.label + ':' }
                                fieldId={ inputData.id }
                                isRequired={ inputData.mandatory }
                            >
                                { input }
                                { helperText }
                            </FormGroup>
                        );
                    })
                }
            </Form>
        );
    }, [ modalParams, params, onChangeInput ]);

    React.useEffect(()=>{
        resetModalToggle();
    }, [ resetModalToggle ]);

    if (props.modalParams.length === 0) {
        return null;
    }

    return (
        <Modal
            id="test-modal"
            position="top"
            variant={ ModalVariant.medium }
            title="Aplicar valores de entrada"
            isOpen={ showModal }
            onClose={ closeModalToggle }
            actions={ [
                <Button key="confirm" variant="primary" onClick={ confirmModalToggle }>
                    Confirmar
                </Button>,
                <Button key="cancel" variant="link" onClick={ resetModalToggle }>
                    Restablecer
                </Button>
            ] }
        >
            {  FormElement }
        </Modal>
    );
};
