import React from 'react';
import { PageLayout } from '../../Layouts/PageLayout';
import { Bullseye } from '@patternfly/react-core';
import { addAlert } from '../../Context/AlertProvider/AlertProviderActions';
import { useAuthState } from '../../Context/AuthProvider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useAlertDispatcherState } from '../../Context/AlertProvider/AlertProvider';

export const Dashboard: React.FunctionComponent = (_props) => {
    const description = '';

    const { user } = useAuthState();
    const navigate = useNavigate();
    const alertDispatch = useAlertDispatcherState();

    //todo: Eliminar, solo se deja este código para la liberación
    React.useEffect(()=>{
        if (user?.info?.jasper_role.reports) {
            const reports = user.info.jasper_role.reports;

            if (reports.length > 0) {
                const lastReport = reports[ reports.length - 1 ];
                navigate(lastReport.key);
            } else {
                addAlert(
                    'No existen reportes asignados',
                    'Tu usuario no tiene reportes asignados. Pongase en contácto con su admisnitrador',
                    'danger',
                    alertDispatch
                );
            }
        }
    }, [ user?.info?.jasper_role.reports, alertDispatch, navigate ]);

    return (
        <PageLayout title="Dashboard" description={ description } >
            <Bullseye>
                <h1>Reportador Diazar</h1>
            </Bullseye>
        </PageLayout>
    );
};
