import React from 'react';
import { DiazarSuiteDispacher, DiazarSuitePayload } from './DiazarSuiteProviderDomain';
import { diazarSuiteInitialState, DiazarSuiteReducer } from './DiazarSuiteProviderReducer';

const AppsStateContext = React.createContext<DiazarSuitePayload>({});
const AppsDispatchContext = React.createContext< React.Dispatch<DiazarSuiteDispacher> >(() => null);

export function useAppsState() {
    const context = React.useContext(AppsStateContext);
    if (context === undefined) {
        throw new Error('useAppsState must be used within a DiazarSuiteProvider');
    }

    return context;
}

export function useAppsDispatch() {
    const context = React.useContext(AppsDispatchContext);
    if (context === undefined) {
        throw new Error('useAppsDispatch must be used within a DiazarSuiteProvider');
    }

    return context;
}

interface ViewProps {
    children?: React.ReactNode | undefined
}

export const DiazarSuiteProvider: React.FunctionComponent<ViewProps> = (props) => {
    const [ apps, dispatch ] = React.useReducer(DiazarSuiteReducer, diazarSuiteInitialState);

    return (
        <AppsStateContext.Provider value={ apps }>
            <AppsDispatchContext.Provider value={ dispatch }>
                { props.children }
            </AppsDispatchContext.Provider>
        </AppsStateContext.Provider>
    );
};
