import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { List, ListItem, LoginFooterItem } from '@patternfly/react-core';
import { ListVariant } from '@patternfly/react-core/dist/esm/components/List/List';

interface InternalLinkItem {
    label:string,
    path:string
}

const links:InternalLinkItem[] = [
    { label: 'Inicio', path: '/' },
    { label: 'Terminos de Uso', path: '/terms-of-use' },
    { label: 'Politicas de Privacidad', path: '/privacy-policies' }
];

interface ViewProps{
    className?:string
}
export const ListItemsInfoLinks: React.FunctionComponent<ViewProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const items = React.useMemo(() => {
        return links.filter(link => link.path !== location.pathname).map((link, index) => {
            return (
                <ListItem key={ index + '' }>
                    <LoginFooterItem href="#"
                        onClick={ event => {
                            event.preventDefault();
                            navigate(link.path);
                        } }
                        className={ props.className }
                    >
                        { link.label }
                    </LoginFooterItem>
                </ListItem>
            );
        });
    }, [ navigate, location.pathname, props.className ]);
    return (
        <React.Fragment>
            <List variant={ ListVariant.inline }>
                { items }
            </List>
        </React.Fragment>
    );
};
