import React from 'react';
import moment, { now } from 'moment';
import { LoginDispacher, UserApp } from './AuthDomain';

const user:UserApp = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser') + '').user
    : {};

const token:string = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser') + '').token
    : '';

let isValidUser = (user.date !== undefined);
if (isValidUser) {
    user.date = moment(user.date);
    isValidUser = user.date.add('1', 'day').diff(now()) > 0;
}

export interface LoginPayload { //HNResponse
    user?:            UserApp
    token?:          string,
    loading?:        boolean,
    errorMessage?:   string
}

export const initialState:LoginPayload = {
    user: (isValidUser) ? undefined || user : undefined,
    token: (isValidUser) ? '' || token : '',
    loading: false,
    errorMessage: undefined
};

export const AuthReducer: React.Reducer<LoginPayload, LoginDispacher> = (initialState: LoginPayload, action: LoginDispacher): LoginPayload => {
    const copy : LoginPayload = { ...initialState, loading: false, errorMessage: undefined };

    switch (action.type) {
        case 'REQUEST_INFO':
        case 'REQUEST_LOGIN':
            return {
                ...initialState,
                loading: true
            };
        case 'INFO_SUCCESS':
            if (action.payload !== undefined && copy.user) {
                copy.user.info = action.payload?.user?.info;
            }

            return copy;

        case 'LOGIN_SUCCESS':

            if (action.payload !== undefined) {
                copy.user = action.payload?.user;
                copy.token = action.payload?.token;
            }

            return copy;
        case 'LOGOUT':
            return {
                ...initialState,
                user: undefined,
                token: '',
                errorMessage: undefined
            };

        case 'INFO_ERROR':
        case 'LOGIN_ERROR':
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        case 'REMOVE_SUB_REPORTS':
            if (action.payload !== undefined && copy.user && copy.user.info) {
                action.payload.user?.info?.jasper_role.reports.map((report) => {
                    const uri = report.uri;
                    const subReports = report.sub_reports;

                    copy.user?.info?.jasper_role.reports
                    .filter(report => report.uri === uri)
                    .map(report => {
                        report.sub_reports = subReports;
                        return true;
                    });

                    return true;
                });
            }

            return copy;

        case 'ADD_SUB_REPORTS':
            if (copy.user && copy.user.info && copy.user.info.jasper_role.reports.length > 0) {
                copy.user.info.jasper_role.reports.map(report => {
                    const uri = report.uri;
                    const subReports = (report.sub_reports) ? report.sub_reports : [];
                    subReports.map(subReport => {
                        subReport.active = false;
                        return true;
                    });

                    action.payload?.user?.info?.jasper_role.reports
                    .filter(payloadReport => payloadReport.uri === uri)
                    .map((payloadReport => {
                        const payloadSubReports = payloadReport.sub_reports;
                        if (payloadSubReports !== undefined) {
                            payloadSubReports
                            .filter(payloadSubReport => {
                                let dontHasReport = true;

                                subReports.map(subReportAux => {
                                    if (subReportAux.id === payloadSubReport.id) {
                                        subReportAux.active = true;
                                        dontHasReport = false;
                                    }

                                    return true;
                                });

                                return dontHasReport;
                            })
                            .map(payloadSubReport => {
                                subReports.push(payloadSubReport);
                                return true;
                            });
                        }

                        return true;
                    }));

                    return true;
                });
            }

            return copy;

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
