import React from 'react';
import { visualizeHelper } from '../VisualizeHelper';
import { addAlert } from '../../Context/AlertProvider/AlertProviderActions';
import { ReportInputControl, UserAppReport } from '../../Context/AuthProvider/AuthDomain';
import { useAuthDispatch, useAuthState } from '../../Context/AuthProvider/AuthProvider';
import { useAlertDispatcherState } from '../../Context/AlertProvider/AlertProvider';
import {
    Bullseye,
    Button,
    ButtonVariant, InputGroup, Pagination,
    Panel,
    PanelMain,
    PanelMainBody, Split, SplitItem,
    Stack,
    StackItem
} from '@patternfly/react-core';
import { DiazarReportZoom } from './DiazarReportZoom';
import { BsChevronBarContract, BsChevronBarExpand } from 'react-icons/bs';
import { GrDocumentCsv, GrDocumentExcel, GrDocumentPdf } from 'react-icons/gr';
import { DiazarModalReportInputControls } from '../Modal/DiazarModalReportInputControls';
import { BiRefresh, BiWindowOpen } from 'react-icons/bi';
import { ImFileEmpty, ImFilesEmpty } from 'react-icons/im';
import { checkLogginJasper } from '../../Context/AuthProvider/AuthProviderActions';

function useWindowSize() {
    const [ size, setSize ] = React.useState([ 0, 0 ]);
    React.useLayoutEffect(() => {
        function updateSize() {
            setSize([ window.innerWidth, window.innerHeight ]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

interface ViewProps {
    id: string,
    keyId: string,
    containerMessage: string,
    report?: UserAppReport,
    showAll: boolean
    setShowAll: React.Dispatch<React.SetStateAction<boolean>>
    reportParams?: any
}

export const DiazarReportContainer: React.FunctionComponent<ViewProps> = (props) => {
    const { id, report, showAll, setShowAll, reportParams, keyId } = props;

    const [ width, height ] = useWindowSize();
    const [ isLoadedReport, setIsLoadedReport ] = React.useState<boolean>(false);
    const [ isLoadingReport, setIsLoadingReport ] = React.useState<boolean>(false);
    const [ valueOfZoom, setValueOfZoom ] = React.useState<number>(100);
    const [ reportMessage, setReportMessage ] = React.useState<string>(props.containerMessage);

    const [ showModalParams, setShowModalParams ] = React.useState<boolean>(false);
    const [ modalParams, setModalParams ] = React.useState<ReportInputControl[]>([]);
    const [ params, setParams ] = React.useState<any>(undefined);
    const [ showReport, setShowReport ] = React.useState<number>(0);
    const [ showParamsCount, setShowParamsCount ] = React.useState<number>(0);

    const userDispatch = useAuthDispatch();
    const alertDispatch = useAlertDispatcherState();

    const [ ignorePagination, setIgnorePagination ] = React.useState<boolean>(false);
    const [ reportJasperData, setReportJasperData ] = React.useState<any>(undefined);
    const [ previousCurrentPage, setPreviousCurrentPage ] = React.useState<number>(1);
    const [ currentPage, setCurrentPage ] = React.useState<number>(1);
    const [ numberOfPages, setNumberOfPages ] = React.useState<number>(1);
    const [ exportType, setExportType ] = React.useState<'pdf'|'xls'|'csv'|undefined>(undefined);

    const { user } = useAuthState();

    /** obtenemos tanto los reportes como las vistas adhoc */
    React.useEffect(()=>{
        if (!isLoadingReport && showReport > 0 && report !== undefined) {
            setIsLoadedReport(false);
            setReportJasperData(undefined);
            setIsLoadingReport(true);

            if (user && user.info) {
                if (!visualizeHelper.hasLogin()) {
                    setReportMessage('Autenticando usuario...');
                    console.log(showReport, 'no existe login');
                    checkLogginJasper(user.info);
                    setTimeout(()=>{
                        setShowReport(prevState => {
                            let state = prevState;
                            state++;
                            return state;
                        }); }, 600);
                    setIsLoadingReport(false);

                } else {
                    setReportMessage('Conectando al Servidor de Reportes...');
                    if (report.is_adhoc) {
                        setIgnorePagination(true);
                        visualizeHelper.getAdHocView(
                            id,
                            report.uri,
                            setReportJasperData,
                            params
                        ).then(_data => {
                            setTimeout(()=>{
                                setIsLoadedReport(true);
                                setReportMessage('Reporte Cargado con éxito');
                                setValueOfZoom(100);
                            }, 300);

                        })
                        .catch(reason => {
                            console.log(reason);
                            setIsLoadedReport(false);

                            addAlert(
                                reason.errorCode,
                                reason.message,
                                'danger',
                                alertDispatch
                            );
                        })
                        .finally(() => {
                            setIsLoadingReport(false);
                            setTimeout(()=>{
                                visualizeHelper.logOut();
                            }, 600000);

                        });
                    } else {
                        visualizeHelper.getReport(
                            userDispatch,
                            id,
                            report.uri,
                            setReportJasperData,
                            setCurrentPage,
                            setNumberOfPages,
                            ignorePagination,
                            params
                        )
                        .then(_data => {
                            setTimeout(()=>{
                                setTimeout(()=>{ setIsLoadedReport(true); }, 300);
                                setValueOfZoom(100);
                            }, 300);
                        })
                        .catch(reason => {
                            console.log(reason);
                            setIsLoadedReport(false);

                            addAlert(
                                reason.errorCode,
                                reason.message,
                                'danger',
                                alertDispatch
                            );
                        })
                        .finally(() => {
                            setIsLoadingReport(false);
                            setTimeout(()=>{
                                visualizeHelper.logOut();
                            }, 600000);
                        });
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id, user, showReport, report, alertDispatch, params, userDispatch, ignorePagination ]);

    /** DECICIÓNCarga de los datos del reporte */
    React.useEffect(()=>{
        if (report) {
            //console.log(report);
            if (report.input_controls.length > 0) {
                const controls:ReportInputControl[] =  report.input_controls;

                setReportMessage('Debe aplicar valores de entrada antes de que el informe se pueda visualizar');
                //setShowReport(0);
                setShowModalParams(showReport === 0);
                setModalParams(controls);
            } else if (report.force_get_inputs) {
                console.log('Se forzan los datos de entrada');
                setShowReport(0);
            } else {
                if (showReport === 0) {
                    setShowReport(prev => prev + 1);
                }
            }
        }
    },  [ report, showReport, keyId ]);

    /** Funcion para repintar el reporte */
    const repaintReport = React.useCallback(()=>{
        if (isLoadedReport) {
            const transformOrigin = [ 0, 0 ];
            const elements = document.querySelectorAll(`#${ id } .visualizejs._jr_report_container_.jr .jrPage`);
            const container = document.getElementById(id) as HTMLElement;

            if (elements.length > 0 && elements[0] !== undefined) {
                const zoom = valueOfZoom / 100;
                const el = elements[0] as HTMLElement;
                const p = [ 'webkit', 'moz', 'ms', 'o' ];
                const s = 'scale(' + zoom + ')';
                const oString = (transformOrigin[0] * 100) + '% ' + (transformOrigin[1] * 100) + '%';

                for (let i = 0; i < p.length; i++) {
                    el.style[p[i] + 'Transform'] = s;
                    el.style[p[i] + 'TransformOrigin'] = oString;
                }

                el.style.transform = s;
                el.style.transformOrigin = oString;

                const newWidth = el.offsetWidth * zoom;
                if (newWidth < container.offsetWidth) {
                    const margin = Math.floor((container.offsetWidth - newWidth) / 2);
                    el.style.marginLeft = `${ margin }px`;
                } else {
                    el.style.marginLeft = '0';
                }
            }
        }
    }, [ id, isLoadedReport, valueOfZoom ]);

    /** Paginación del reporte */
    const pagination = React.useMemo(()=>{
        if (ignorePagination) {
            return null;
        }

        return <Pagination
            itemCount={ numberOfPages }
            perPage={ 1 }
            page={ currentPage }
            onSetPage={ (_event, page)=>{
                setCurrentPage(page);
            } }
            widgetId="pagination-report-menu-top"
        />;
    }, [ ignorePagination, currentPage, numberOfPages ]);

    /** on Windows change */
    React.useEffect(() => {
        setTimeout(()=>{ repaintReport(); }, 300);
    }, [ width, height, isLoadedReport, valueOfZoom, alertDispatch, repaintReport ]);

    /** si se carga el reporte se repinta despues de x tiempo para centrarlo */
    React.useEffect(() => {
        setTimeout(()=>{ repaintReport(); }, 1000);
    }, [ showReport, isLoadedReport, repaintReport ]);

    /** aciones del paginador */
    React.useEffect(() => {

        if (isLoadedReport && reportJasperData && previousCurrentPage !== currentPage) {
            reportJasperData.pages(currentPage).run()
            .done(()=>{
                setIsLoadedReport(false);
                setPreviousCurrentPage(currentPage);
                setIsLoadedReport(true);
            })
            .fail(()=>{
                setShowReport(prevState => prevState + 1);
            });
        }
    }, [ isLoadedReport, currentPage, previousCurrentPage, reportJasperData ]);

    /** Parametros (inputs) para la carga del pryeto */
    React.useEffect(()=>{
        console.log('reportParams', reportParams);
        if (report && report.input_controls.length === 0 && reportParams) {
            setParams(reportParams);
        }
    }, [ report, reportParams ]);

    /** botones para exportaer el reporte */
    const exportReportButtons = React.useMemo(()=>{
        if (report && report.is_adhoc) {
            return null;
        }

        return (
            <InputGroup>
                <Button variant={ ButtonVariant.control }
                    onClick={ ()=>{
                        setExportType('pdf');
                    } }
                    className="report-head-icon"
                    isDisabled={ !isLoadedReport }
                >
                    <GrDocumentPdf />
                </Button>
                <Button variant={ ButtonVariant.control }
                    onClick={ ()=>{
                        setExportType('xls');
                    } }
                    className="report-head-icon"
                    isDisabled={ !isLoadedReport }
                >
                    <GrDocumentExcel />
                </Button>
                <Button variant={ ButtonVariant.control }
                    onClick={ ()=>{
                        setExportType('csv');
                    } }
                    className="report-head-icon"
                    isDisabled={ !isLoadedReport }
                >
                    <GrDocumentCsv />
                </Button>
            </InputGroup>
        );
    }, [ isLoadedReport, report ]);

    /** Acion de exportar reporte */
    React.useEffect(()=>{
        if (isLoadedReport && reportJasperData && exportType) {
            reportJasperData.export({
                outputFormat: exportType
            },
            (link)=>{
                const url = link.href ? link.href : link;
                window.location.href = url;

                setExportType(undefined);
            },
            (error)=>{
                console.log(error);
                setShowReport(prevState => prevState + 1);
            }
            );
        }
    }, [ isLoadedReport, exportType, reportJasperData ]);

    React.useEffect(()=>{
        console.log('cambios', report);
    }, [ report ]);

    /** Botones en la cabecera */
    const reportButtonsControls = React.useMemo(()=>{
        return (
            <InputGroup>
                <Button className="report-head-icon"
                    onClick={ ()=>{ setShowAll(prevState => !prevState); } }
                    variant={ ButtonVariant.control }
                >
                    { (showAll) ?  <BsChevronBarContract /> : <BsChevronBarExpand /> }
                </Button>
                { (modalParams.length > 0) ? (
                    <Button className="report-head-icon"
                        onClick={ ()=>{ setShowModalParams(true); } }
                        variant={ ButtonVariant.control }
                    >
                        <BiWindowOpen />
                    </Button>
                ) : null }
                <Button className="report-head-icon"
                    onClick={ ()=>{ setShowReport(prevState => prevState + 1); } }
                    variant={ ButtonVariant.control }
                    isDisabled={ !isLoadedReport }
                >
                    <BiRefresh />
                </Button>
                <Button className="report-head-icon"
                    onClick={ ()=>{ setIgnorePagination(prevState => !prevState); } }
                    variant={ ButtonVariant.control }
                    isDisabled={ (report && report.is_adhoc) ? true : false }
                >
                    { (ignorePagination) ? <ImFileEmpty /> : <ImFilesEmpty /> }
                </Button>
            </InputGroup>
        );
    }, [ showAll, isLoadedReport, ignorePagination, setShowAll, modalParams, report ]);

    /** Forzamos la carga de los inputs */
    React.useEffect(()=>{
        if (
            showParamsCount >= 0 && user && user.info
            && report && report.input_controls.length === 0 && report.force_get_inputs
            && (params === undefined || params.length === 0)
        ) {
            setReportMessage('Obteniendo valores de entrada...');

            if (!visualizeHelper.hasLogin()) {
                console.log('Autenticando usuario...');
                setReportMessage('Autenticando usuario...');
                checkLogginJasper(user.info);

                setTimeout(()=>{
                    setShowParamsCount(prevState => {
                        let newState = prevState;
                        newState++;

                        return newState;
                    });
                }, 400);
            } else {
                setReportMessage('Obteniendo valores de entrada...');
                console.log('----> Forzamos la carga de los inputs');
                visualizeHelper.getInputControl(null, report.uri)
                .then(data => {
                    const auxParameters : ReportInputControl[] = [];
                    let dataParameters : any = {};

                    console.log(data);
                    if (typeof data === 'object') {
                        for (const i in data) {
                            if (i === 'parameters') {
                                dataParameters = data[i];
                            } else {
                                const element = data[i];
                                const aux:ReportInputControl = {
                                    id: element.id,
                                    label: element.label,
                                    description: (element.description) ? element.description : '',
                                    value: '',
                                    values: undefined,
                                    type: element.type,
                                    visible: element.visible,
                                    mandatory: true,
                                    readOnly: element.readOnly,
                                    dataType: element.dataType
                                };

                                if (element.state) {
                                    if (element.state.options) {
                                        aux.values = element.state.options
                                        .filter(option => option.label !== '---')
                                        .map(option => {
                                            return {
                                                id: option.value,
                                                value: option.label
                                            };
                                        });
                                    } else if (element.state.value) {
                                        aux.value = element.state.value;
                                    }
                                }

                                auxParameters.push(aux);
                            }
                        }

                        let auxParams: any = undefined;

                        for (const i in dataParameters) {
                            if (dataParameters[i].length > 0) {
                                if (auxParams === undefined) {
                                    auxParams = {};
                                }

                                if (dataParameters[i].length === 1) {
                                    auxParams[i] =  dataParameters[i][0];
                                } else {
                                    auxParams[i] = dataParameters[i];
                                }
                            }
                        }

                        if (auxParams !== undefined) {
                            setParams(auxParams);
                        }

                        setModalParams(auxParameters);

                        setReportMessage('Aplique los valores de entrada para ejecuttar el reporte...');
                        setShowModalParams(true);

                        // setReportMessage( "Debe aplicar valores de entrada" )
                        //                             setShowModalParams( true )
                    }

                });
            }
        }

    }, [ user, report, params, showParamsCount ]);

    /** Bandera para decidir que reporte se va ha mostrar */
    const showReportContainer = React.useMemo(()=>{
        let flag = false;
        if (isLoadingReport) {
            flag = true;
        } else {
            if (isLoadedReport) {
                flag = true;
            }
        }

        return flag;
    }, [ isLoadedReport, isLoadingReport ]);

    React.useEffect(()=>{
        if (keyId !== '') {
            setIsLoadedReport(false);
            setIsLoadingReport(false);
            setParams(undefined);
        }
    }, [ keyId ]);

    return (
        <React.Fragment>

            <DiazarModalReportInputControls
                setParamsResult={ setParams }
                showModal={ showModalParams }
                setShowModal={ setShowModalParams }
                modalParams={ modalParams }
                showReport={ setShowReport }
            />

            <Stack className="main-report-stack">
                <StackItem>
                    <Panel variant="raised">
                        <PanelMain>
                            <PanelMainBody>
                                <Split hasGutter>
                                    <SplitItem>
                                        { reportButtonsControls }
                                    </SplitItem>
                                    <SplitItem>
                                        { exportReportButtons }
                                    </SplitItem>
                                    <SplitItem isFilled className="pagination-report">
                                        { pagination }
                                    </SplitItem>
                                </Split>
                            </PanelMainBody>
                        </PanelMain>
                    </Panel>
                </StackItem>
                <StackItem isFilled>
                    <div className={ `report report-container${ (showReportContainer) ? ' hidden' : '' }` }>
                        <Bullseye >
                            <h1 style={ { textAlign: 'center', fontSize: '30px' } }>{ reportMessage }</h1>
                        </Bullseye>
                    </div>
                    <div id={ id } className={ `report report-container${ (!showReportContainer) ? ' hidden' : '' }` }>
                        <Bullseye >
                            <h1 style={ { textAlign: 'center', fontSize: '30px' } }>{ reportMessage }</h1>
                        </Bullseye>
                    </div>
                </StackItem>
                { (report && !report.is_adhoc) ?
                    (
                        <StackItem>
                            <DiazarReportZoom reportContainerID={ id }
                                setValueOfZoom={ setValueOfZoom }
                                valueOfZoom={ valueOfZoom }
                                isLoadedReport={ isLoadedReport } />
                        </StackItem>
                    ) :
                    null
                }
            </Stack>

        </React.Fragment>
    );
};
