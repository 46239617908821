import React from 'react';
import styles from './notfound.module.css';

export const NotFound: React.FunctionComponent = (_props) => {
    return (
        <div className={ styles.container }>
            <h1>Page not found</h1>
        </div>
    );
};
