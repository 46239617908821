import React from 'react';
import { AlertData, AlertDispacher } from './AlertProviderDomain';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function addAlert(
    title:string,
    body:string|undefined,
    variant:'success' | 'danger' | 'warning' | 'info' | 'default',
    dispatch:React.Dispatch<AlertDispacher>
) {
    const data:AlertData = {
        id: uuidv4(),
        finish: moment().add(5, 'seconds'),
        title,
        variant,
        body
    };

    dispatch({ type: 'ADD_ALERT', payload: data });
}

export function removeAlert(id:string,  dispatch:React.Dispatch<AlertDispacher>) {
    const data:AlertData = { id };

    dispatch({ type: 'REMOVE_ALERT', payload: data });
}
