import React from 'react';
import { AlertData, AlertDispacher } from './AlertProviderDomain';

export const alertDataInitialState:AlertData[] = [];

export const AlertReducer: React.Reducer<AlertData[], AlertDispacher> = (alertDataInitialState:AlertData[], action:AlertDispacher) : AlertData[] => {
    switch (action.type) {
        case 'ADD_ALERT' :
            if (
                action.payload !== undefined &&
                alertDataInitialState.filter(
                    l => l.title === action.payload?.title && l.body === action.payload?.body
                ).length === 0
            ) {
                return [ ...alertDataInitialState, action.payload ];
            }

            return alertDataInitialState;

        case 'REMOVE_ALERT':
            if (action.payload !== undefined) {
                return alertDataInitialState.filter(l => l.id !== action.payload?.id);
            }

            return alertDataInitialState;

        default:
            return alertDataInitialState;
    }
};
