import React from 'react';
import { bg_images, tint_bg_image } from '../Config/Images';
import { BackgroundImage } from '@patternfly/react-core';

export const DiazarBackgroundImage:React.FunctionComponent = (_props)=>{
    const defaultFilter = React.useMemo(()=>{
        const base = 255;
        const R = tint_bg_image.r / base;
        const G = tint_bg_image.g / base;
        const B = tint_bg_image.b / base;

        const matrix =  `${ R } 0 0 0 0 ` +
                        `0 ${ G } 0 0 0 ` +
                        `0 0 ${ B } 0 0 ` +
                        '0 0 0 0 1';
        return (
            <filter>
                <feColorMatrix type="matrix"
                    values={ matrix }></feColorMatrix>
            </filter>
        );
    }, []);

    return (
        <BackgroundImage src={ bg_images } filter={ defaultFilter } />
    );
};
