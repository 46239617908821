import React, { useState } from 'react';
import { loginUser } from '../../Context/AuthProvider/AuthProviderActions';
import { useAuthDispatch, useAuthState } from '../../Context/AuthProvider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { FormHelperText, ActionGroup, Form, Button } from '@patternfly/react-core';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { useAlertDispatcherState } from '../../Context/AlertProvider/AlertProvider';
import { addAlert } from '../../Context/AlertProvider/AlertProviderActions';
import { LoginPayloadRequest } from '../../Context/AuthProvider/AuthDomain';
import { DiazarFormInput } from '../../Components/Form/DiazarFormInput';
import { COOKIE_NAME, APP_ID } from '../../Config/UrlRuotes';
import { useCookies } from 'react-cookie';

export const DiazarLoginForm: React.FunctionComponent = (_props) => {
    const [ username, setUserName ] = useState<string>('');
    const [ isValidUsername, setIsValidUserName ] = useState<boolean>(false);

    const [ password, setPassword ] = useState<string>('');
    const [ isValidPassword, setIsValidPassword ] = useState<boolean>(false);

    const [ company, setCompany ] = useState<string>('');
    const [ isValidCompany, setIsValidCompany ] = useState<boolean>(false);

    const dispatch = useAuthDispatch();
    const alertDispatch = useAlertDispatcherState();

    const { loading, errorMessage } = useAuthState();

    const navigate = useNavigate();

    const [ cookies, setCookie ] = useCookies([ COOKIE_NAME ]);
    const addSessionCoockie = React.useCallback((token:string)=>{
        let cookieObj = (cookies[COOKIE_NAME] !== undefined) ? cookies[COOKIE_NAME] : [];
        cookieObj = cookieObj.filter(val => val.id !== APP_ID);
        cookieObj.push({ id: APP_ID, token });

        setCookie(COOKIE_NAME, cookieObj, { path: '/', domain: 'diazar.com' });
    }, [ cookies, setCookie ]);

    const handleLogin = React.useCallback(async ()=>{
        const payload:LoginPayloadRequest = { company, username, password };

        try {
            const responseLogin = await loginUser(payload, dispatch);
            if (!responseLogin || !responseLogin.response || !responseLogin.response.user || responseLogin.response.user.id <= 0) {return;}

            addAlert('Autentificación exitosa', `Bienvenido ${ responseLogin.response.user.clave }`, 'success', alertDispatch);
            addSessionCoockie(responseLogin.response.token);
            navigate('/');

        } catch (error) {
            console.log(error);
        }

    }, [ company, username, password, dispatch, navigate, alertDispatch, addSessionCoockie ]);

    const isValidForm = React.useMemo<boolean>(() => {
        const isLoading = (loading !== undefined) ? loading : false;
        return !isLoading && isValidCompany && isValidUsername && isValidPassword;

    }, [ isValidCompany, isValidUsername, isValidPassword, loading ]);

    const helperText = React.useMemo(() => {
        if (errorMessage === undefined) {
            return null;
        } else {
            return (
                <FormHelperText isError={ true } isHidden={ false }>
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            );
        }
    }, [ errorMessage ]);

    return (
        <React.Fragment>
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <Form>
                        { helperText }
                        <DiazarFormInput id="login-clave" label="Clave" isRequired
                            type="text"
                            placeholder="Clave de Usuario"
                            getState={ (isValid, value) => {
                                setIsValidUserName(isValid);
                                setUserName(value);
                            } }
                        />

                        <DiazarFormInput id="login-password" label="Contraseña" isRequired
                            type="password"
                            placeholder="Contraseña de Usuario"
                            getState={ (isValid, value) => {
                                setIsValidPassword(isValid);
                                setPassword(value);
                            } }
                        />

                        <DiazarFormInput id="login-company" label="Empresa" isRequired
                            type="text"
                            placeholder="Empresa de Usuario"
                            getState={ (isValid, value) => {
                                setIsValidCompany(isValid);
                                setCompany(value);
                            } }
                        />

                        <ActionGroup>
                            <Button type="submit"
                                onClick={ e => { e.preventDefault(); handleLogin(); } }
                                isBlock
                                isDisabled={ !isValidForm }
                                isLoading={ (loading !== undefined) ? loading : false }
                            >
                                Enviar
                            </Button>
                        </ActionGroup>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
};
