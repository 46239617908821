import React from 'react';

export const bg_images = {
    lg: '/assets/images/pfbg_1200.jpg',
    sm: '/assets/images/pfbg_768.jpg',
    sm2x: '/assets/images/pfbg_768@2x.jpg',
    xs: '/assets/images/pfbg_576.jpg',
    xs2x: '/assets/images/pfbg_576@2x.jpg'
};

export const tint_bg_image = { r: 4, g: 25, b: 53 };

export const big_reverse_logo = '/assets/images/Diazar_Reports_Logo_BN.svg';
export const big_logo = '/assets/images/Diazar_Reports_Logo_Blue.svg';

export const DiazarBadge : React.FunctionComponent = (_props)=>{
    return (
        <svg version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            fill="currentColor"
            width='25px' height='25px'
        >
            <path d="M13,0.4c0.6,0.2,1.1,0.6,1.6,1c0.5,0.4,1,0.6,1.7,0.6c0.4,0,0.8,0,1.3,0c0.7,0,1.1,0.3,1.4,1c0.1,0.4,0.3,0.8,0.4,1.2
                c0.2,0.6,0.5,1,0.9,1.3C20.6,5.7,21,6,21.3,6.3c0.6,0.4,0.8,1,0.5,1.7c-0.1,0.4-0.3,0.8-0.4,1.2c-0.2,0.5-0.2,1.1,0,1.6
                c0.2,0.5,0.3,1,0.5,1.5c0.1,0.5,0,0.9-0.4,1.3c-0.3,0.3-0.7,0.5-1.1,0.8c-0.6,0.4-1,0.9-1.2,1.6c-0.1,0.4-0.3,0.8-0.4,1.2
                c0,0.1,0,0.2,0,0.3c0.6,1.6,1.2,3.1,1.8,4.6c0.2,0.5,0,0.8-0.6,0.8c-0.8-0.1-1.5-0.2-2.2-0.3c-0.3,0-0.4,0-0.6,0.2
                c-0.5,0.6-1,1.2-1.5,1.7c-0.3,0.4-0.7,0.3-0.9-0.2c-0.6-1.5-1.2-3-1.8-4.6c-0.1-0.3-0.2-0.4-0.5-0.3c-0.2,0.4-0.3,0.8-0.5,1.2
                c-0.5,1.2-1,2.4-1.4,3.7c-0.2,0.5-0.6,0.6-1,0.2c-0.5-0.6-1-1.2-1.5-1.7c-0.2-0.2-0.3-0.2-0.6-0.2c-0.8,0.1-1.5,0.2-2.3,0.3
                c-0.5,0.1-0.8-0.3-0.6-0.8C5.4,20.6,6,19,6.6,17.4c0.1-0.2,0.1-0.3,0-0.4c-0.2-0.4-0.3-0.9-0.4-1.3c-0.2-0.6-0.5-1-1-1.3
                c-0.4-0.2-0.7-0.5-1-0.7c-0.6-0.4-0.8-1-0.5-1.7c0.1-0.4,0.3-0.8,0.4-1.2c0.2-0.5,0.2-1.1,0-1.6c-0.2-0.5-0.3-1-0.5-1.5
                C3.5,7.1,3.7,6.6,4.2,6.3C4.5,6,4.9,5.7,5.3,5.5C5.8,5.2,6,4.7,6.2,4.2c0.2-0.5,0.3-1,0.5-1.5C6.9,2.2,7.4,2,8,1.9
                c0.5,0,0.9,0,1.4,0c0.6,0,1.1-0.1,1.5-0.5c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.3,0.8-0.6,1.3-0.8C12.7,0.4,12.8,0.4,13,0.4z M8.9,2.9
                c-0.3,0-0.6,0-0.9,0C7.8,2.9,7.6,3,7.5,3.2c-0.1,0.3-0.2,0.6-0.3,1C7,5.2,6.5,6,5.6,6.5C5.3,6.7,5,6.9,4.8,7.1
                C4.6,7.2,4.5,7.4,4.6,7.6c0.1,0.3,0.2,0.7,0.3,1c0.4,0.9,0.4,1.9,0,2.8c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2,0,0.4,0.2,0.5
                c0.3,0.2,0.5,0.4,0.8,0.6C6.5,14,7,14.7,7.2,15.7c0.1,0.3,0.2,0.7,0.3,1C7.6,16.9,7.8,17,8,17c0.4,0,0.8,0,1.1,0
                c1-0.1,1.8,0.2,2.6,0.9c0.2,0.2,0.4,0.3,0.6,0.5c0.4,0.3,0.6,0.3,1,0c0.2-0.1,0.4-0.3,0.6-0.4c0.8-0.7,1.7-1,2.7-0.9
                c0.3,0,0.7,0,1,0c0.2,0,0.4-0.1,0.4-0.3c0.1-0.3,0.2-0.7,0.3-1c0.2-1,0.8-1.7,1.6-2.2c0.3-0.2,0.6-0.4,0.8-0.6
                c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.3-0.2-0.7-0.3-1c-0.4-0.9-0.4-1.9,0-2.8c0.1-0.3,0.2-0.6,0.3-1c0.1-0.2,0-0.4-0.2-0.5
                c-0.3-0.2-0.6-0.4-0.9-0.6c-0.8-0.5-1.3-1.2-1.5-2.1C18.2,4,18.1,3.6,18,3.3c-0.1-0.3-0.3-0.4-0.5-0.4c-0.4,0-0.7,0-1.1,0
                c-0.7,0-1.4-0.1-2.1-0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.7-0.6-0.8-0.6-1.5,0c-0.4,0.3-0.8,0.6-1.2,0.8C10.2,3,9.6,3,8.9,2.9z
                 M10,23.4c0.6-1.4,1.1-2.8,1.6-4.2c0-0.1,0-0.2-0.1-0.2c-0.2-0.2-0.4-0.3-0.7-0.5c-0.5-0.4-1.1-0.6-1.7-0.6c-0.6,0-1.1,0-1.7,0
                c-0.5,1.2-1,2.5-1.5,3.9c0.7-0.1,1.3-0.2,2-0.2c0.3,0,0.6,0,0.8,0.3C9.1,22.4,9.5,22.9,10,23.4z M15.5,23.4
                c0.4-0.5,0.8-0.9,1.1-1.3c0.3-0.4,0.6-0.6,1.1-0.5c0.1,0,0.2,0,0.3,0c0.5,0.1,1,0.1,1.5,0.2c-0.5-1.3-1-2.6-1.5-3.9
                c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0-1,0-1.6,0c-0.3,0-0.7,0-1,0.2c-0.5,0.2-0.8,0.6-1.3,0.9c0,0,0,0.1,0,0.2C14.4,20.6,15,22,15.5,23.4
                z"/>
            <path d="M19.1,10c0,3.5-2.8,6.3-6.3,6.3c-3.5,0-6.3-2.9-6.3-6.3c0-3.5,2.8-6.3,6.3-6.3C16.2,3.7,19.1,6.5,19.1,10z M12.8,4.6
                C9.8,4.6,7.5,7,7.5,10c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3C18.1,7,15.7,4.6,12.8,4.6z"/>
            <path d="M10.3,12.8c0.1-0.6,0.2-1.2,0.3-1.8c0-0.2,0-0.2-0.1-0.3c-0.4-0.4-0.8-0.8-1.2-1.1C9.2,9.4,9,9.2,9.1,9
                c0.1-0.2,0.3-0.3,0.5-0.3c0.6-0.1,1.1-0.2,1.7-0.2c0.1,0,0.2-0.1,0.2-0.2c0.3-0.5,0.5-1,0.7-1.5c0.1-0.2,0.2-0.4,0.5-0.4
                c0.3,0,0.4,0.2,0.5,0.4c0.2,0.5,0.5,1,0.7,1.5c0,0.1,0.2,0.2,0.3,0.2c0.5,0.1,1.1,0.2,1.7,0.2c0.2,0,0.4,0.1,0.5,0.3
                c0.1,0.2,0,0.4-0.2,0.6c-0.4,0.4-0.8,0.8-1.2,1.2c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0.5,0.2,1.1,0.3,1.6c0,0.2,0,0.5-0.2,0.6
                c-0.2,0.2-0.4,0.1-0.6,0c-0.5-0.3-1-0.5-1.4-0.8c-0.1-0.1-0.3-0.1-0.4,0c-0.5,0.3-1,0.5-1.5,0.8C10.7,13.5,10.3,13.2,10.3,12.8z
                 M11.4,12c0.3-0.1,0.5-0.2,0.7-0.4c0.4-0.3,0.8-0.3,1.3,0c0.2,0.1,0.4,0.2,0.7,0.4c0-0.3-0.1-0.4-0.1-0.6c-0.1-0.9-0.3-0.7,0.5-1.4
                c0.2-0.1,0.3-0.3,0.5-0.5c-0.4-0.1-0.8-0.1-1.1-0.2c-0.3,0-0.4-0.2-0.5-0.4c-0.2-0.3-0.3-0.6-0.5-1c-0.2,0.4-0.3,0.7-0.5,1
                c-0.1,0.2-0.3,0.3-0.5,0.4c-0.4,0-0.7,0.1-1.1,0.2c0.3,0.3,0.5,0.6,0.8,0.8c0.2,0.2,0.2,0.3,0.2,0.6C11.6,11.2,11.5,11.6,11.4,12z"
            />

        </svg>
    );
};

export const DiazarSecure : React.FunctionComponent = (_props)=>{
    return (
        <svg version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            fill="currentColor"
            width='25px' height='25px'
        >
            <path className="st0" d="M2.9,8.4c0-1.5,0-2.9,0-4.4c0-0.2,0-0.3,0.2-0.3c3-1.1,6.1-2.2,9.1-3.4c0.2-0.1,0.3-0.1,0.5,0
		c3,1.1,6,2.2,9.1,3.4C22,3.7,22.1,3.8,22.1,4c0,2.7,0,5.5,0,8.2c0,3.7-1.5,6.6-4.4,8.8c-1.6,1.2-3.3,2.5-5,3.7
		c-0.2,0.1-0.3,0.1-0.4,0c-1.7-1.3-3.5-2.5-5.2-3.9c-2.5-1.9-3.9-4.5-4.1-7.6C2.9,11.6,3,10,2.9,8.4C3,8.4,3,8.4,2.9,8.4z M4.5,4.9
		c0,0.1,0,0.2,0,0.3c0,2.4,0,4.7,0,7.1c0,3.1,1.3,5.5,3.7,7.4c0.9,0.7,1.9,1.4,2.8,2.1c0.5,0.4,1,0.7,1.5,1.1c1.5-1.1,3-2.2,4.4-3.3
		c2.3-1.8,3.5-4.2,3.5-7.2c0-2.4,0-4.8,0-7.2c0-0.1,0-0.2,0-0.2c0,0-0.1,0-0.1-0.1c-2.6-1-5.1-1.9-7.7-2.9c-0.1,0-0.2,0-0.3,0
		c-1.8,0.7-3.7,1.4-5.5,2C6.1,4.3,5.3,4.6,4.5,4.9z"/>
            <path className="st0" d="M8,11.6c0.4-0.4,0.7-0.7,1.1-1.1c0.8,0.8,1.6,1.6,2.4,2.4c1.5-1.5,3-3,4.4-4.5c0.4,0.4,0.8,0.8,1.1,1.2
		c-1.8,1.8-3.6,3.7-5.5,5.6C10.3,14,9.2,12.8,8,11.6z"/>
        </svg>
    );
};
