import React from 'react';
import { ReportInputControl, ReportSelectOption } from '../../Context/AuthProvider/AuthDomain';
import {
    Select,
    SelectOption,
    SelectVariant
} from '@patternfly/react-core';
import { DiazarInputControlMultiSelect } from './DiazarInputControlMultiSelect';

export interface SelectViewProps {
    inputData: ReportInputControl
    onChange: (id: string, inputVal: any) => void
    selected: string|string[]
}

const choises = [ 'holas-borolas-bartolas', 'holas-borolas' ];

export const DiazarInputControlSelect: React.FunctionComponent<SelectViewProps> = (props) => {
    const [ isOpen, setIsOpen ] = React.useState<boolean>(false);
    const [ options, setOptions ] = React.useState<ReportSelectOption[]>([]);

    const isMulty = React.useMemo(()=>{
        return props.inputData.type === 'multiSelect';
    }, [ props.inputData ]);

    const defaultOptions = React.useMemo<ReportSelectOption[]>(()=>{
        if (props.inputData.values ===  undefined)
        {return [];}

        return props.inputData.values.map(val => {
            if (typeof val === 'string') {
                return { id: val, value: val };
            } else {
                return val;
            }
        });
    }, [ props.inputData.values ]);

    const onToggle = React.useCallback((isExpanded, _event)=>{
        if (!(_event.target && choises.includes(_event.target.id))) {
            setIsOpen(isExpanded);
        }
    }, []);

    const clearSelection = React.useCallback(() => {
        setIsOpen(true);
        setOptions(defaultOptions);

        if (isMulty) {
            props.onChange(props.inputData.id, []);
        } else {
            props.onChange(props.inputData.id, defaultOptions[0].value);
        }
    }, [ defaultOptions, props, isMulty ]);

    const onSelect = React.useCallback((_event, selection) => {
        setIsOpen(false);
        props.onChange(props.inputData.id, selection);
    }, [ props ]);

    React.useEffect(()=>{
        let dOpts = [] as ReportSelectOption[];
        if (defaultOptions !== undefined) {
            dOpts = defaultOptions;
        }

        setOptions(dOpts);
        //setSelected( dOpts[0].value )
    }, [ defaultOptions ]);

    if (isMulty) {
        return (<DiazarInputControlMultiSelect { ...props } />);
    }

    return (

        <Select
            variant={ (!isMulty) ? SelectVariant.typeahead : SelectVariant.typeaheadMulti }
            typeAheadAriaLabel={ props.inputData.label }
            onToggle={ onToggle }
            onSelect={ onSelect }
            onClear={ clearSelection }
            selections={ props.selected }
            isOpen={ isOpen }
            aria-labelledby={ props.inputData.id }
            isInputValuePersisted={ false }
            isInputFilterPersisted={ false }
            placeholderText={ props.inputData.label }
            isDisabled={ props.inputData.readOnly }
            isCreatable={ false }
            shouldResetOnSelect={ true }
            menuAppendTo={ () => document.body }
            id={ props.inputData.id }
            style={ { maxHeight: 'calc( 100vh - 600px)', width: '700px', overflow: 'scroll' } }
        >
            {options.map((option, index) => (
                <SelectOption
                    isDisabled={ false }
                    key={ index }
                    value={ option.id }
                    isSelected={ props.selected === option.value }
                >{option.value}</SelectOption>
            ))}
        </Select>
    );
};
