import React, { Dispatch, SetStateAction } from 'react';
import {
    Button, Flex, FlexItem,
    Slider, Tooltip
} from '@patternfly/react-core';
import { MinusIcon, PlusIcon } from '@patternfly/react-icons';
import { FaArrowsAltH, FaArrowsAltV, FaArrowsAlt } from 'react-icons/fa';

interface ViewProps{
    reportContainerID: string
    isLoadedReport:  boolean
    valueOfZoom: number
    setValueOfZoom: Dispatch<SetStateAction<number>>
}

export const DiazarReportZoom: React.FunctionComponent<ViewProps> = (props) => {
    const onChangeZoomValue = React.useCallback((value:number)=>{
        const newValue = Math.floor(Number(value));
        props.setValueOfZoom(newValue);
    }, [ props ]);
    const onMinusClickZoomValue = React.useCallback(()=>{
        const newValue = props.valueOfZoom - 5;
        props.setValueOfZoom(newValue);
    }, [  props ]);
    const onPlusClickZoomValue = React.useCallback(()=>{
        const newValue = props.valueOfZoom + 5;
        props.setValueOfZoom(newValue);
    }, [ props ]);

    const adjustOfWidth = React.useCallback(()=>{
        const elements = document.querySelectorAll(`#${ props.reportContainerID } .visualizejs._jr_report_container_.jr .jrPage`);
        if (elements.length > 0 && elements[0] !== undefined) {
            const el = elements[0] as HTMLElement;
            const elWidth = el.offsetWidth;

            const container = document.getElementById(props.reportContainerID) as HTMLElement;
            const contWidth = container.offsetWidth;

            onChangeZoomValue(contWidth / elWidth * 100);
        }
    }, [ props.reportContainerID, onChangeZoomValue ]);

    const adjustOfHeight = React.useCallback(()=>{
        const elements = document.querySelectorAll(`#${ props.reportContainerID } .visualizejs._jr_report_container_.jr .jrPage`);
        if (elements.length > 0 && elements[0] !== undefined) {
            const el = elements[0] as HTMLElement;
            const elWidth = el.offsetWidth;

            const container = document.getElementById(props.reportContainerID) as HTMLElement;
            const contWidth = container.offsetWidth;

            onChangeZoomValue(elWidth / contWidth  * 100);
        }
    }, [ props.reportContainerID, onChangeZoomValue ]);

    if (!props.isLoadedReport) {
        return null;
    }

    return (
        <React.Fragment>

            <Flex hidden={ props.isLoadedReport }
                className="button-zoom-options"
                justifyContent={ { default: 'justifyContentFlexEnd' } }
            >
                <FlexItem>
                    Zoom: { props.valueOfZoom } %
                </FlexItem>
                <FlexItem>
                    <Tooltip content={ <div>Ajunstar Ancho de página</div> } flipBehavior={ [ 'top' ] }>
                        <Button variant="control" onClick={ adjustOfWidth }>
                            <FaArrowsAltH />
                        </Button>
                    </Tooltip>

                    <Tooltip content={ <div>Ajunstar Alto de página</div> } flipBehavior={ [ 'top' ] }>
                        <Button variant="control" onClick={ adjustOfHeight }>
                            <FaArrowsAltV />
                        </Button>
                    </Tooltip>

                    <Tooltip content={ <div>Tamaño Real</div> } flipBehavior={ [ 'top' ] }>
                        <Button variant="control" onClick={ ()=>{ onChangeZoomValue(100); } }>
                            <FaArrowsAlt />
                        </Button>
                    </Tooltip>
                </FlexItem>
                <FlexItem style={ { width: '200px' } }>
                    <Slider
                        value={ props.valueOfZoom }
                        onChange={ onChangeZoomValue }
                        leftActions={ <Button variant="plain" aria-label="Minus" onClick={ onMinusClickZoomValue }>
                            <MinusIcon />
                        </Button> }
                        rightActions={ <Button variant="plain" aria-label="Plus" onClick={ onPlusClickZoomValue }>
                            <PlusIcon />
                        </Button> }
                        min={ 0 }
                        max={ 400 }
                        showBoundaries={ false }
                    />
                </FlexItem>
                <FlexItem>&nbsp;</FlexItem>
            </Flex>
        </React.Fragment>
    );
};
