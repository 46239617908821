import React from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../Layouts/PageLayout';
import { UserAppReport, UserAppReportPair } from '../../Context/AuthProvider/AuthDomain';
import {
    DescriptionList, DescriptionListDescription,
    DescriptionListGroup,
    DescriptionListTerm,
    Text,
    TextContent,
    TextVariants
} from '@patternfly/react-core';
import { addAlert } from '../../Context/AlertProvider/AlertProviderActions';
import { useAuthState } from '../../Context/AuthProvider/AuthProvider';
import { useAlertDispatcherState } from '../../Context/AlertProvider/AlertProvider';
import { DiazarReportContainer } from '../../Components/Reports/DiazarReportContainer';

interface subReportParamData {
    label: string
    value: string
}

export const SubReport: React.FunctionComponent = (_props) => {
    const { user } = useAuthState();
    const { id, subId } = useParams();

    const alertDispatch = useAlertDispatcherState();

    const [ selectedReport, setSelectedReport ] = React.useState<UserAppReport|undefined>(undefined);
    const [ selectedSubreport, setSelectedSubreport ] = React.useState<UserAppReportPair|undefined>(undefined);

    const [ showAll, setShowAll ] = React.useState<boolean>(false);

    const report = React.useMemo<UserAppReport|undefined>(()=>{
        if (selectedReport && selectedSubreport) {
            const report : UserAppReport = {
                id: selectedSubreport.id,
                key: selectedReport.key + '-' + selectedSubreport.id,
                name: selectedReport.name,
                description: selectedReport.description,
                input_controls: [],
                label: selectedReport.label,
                uri: selectedSubreport.resource,
                is_adhoc: false,
                force_get_inputs: false
            };

            return report;
        }

        return undefined;

    }, [ selectedReport, selectedSubreport ]);

    const reportParams = React.useMemo(()=>{
        if (selectedSubreport !== undefined) {
            return selectedSubreport.parameters;
        }

        return [];
    }, [ selectedSubreport ]);

    const description = React.useMemo(()=>{
        if (selectedReport === undefined) {
            return 'Descripción del Reporte';
        }

        if (selectedSubreport === undefined) {
            return 'Descripción del Reporte';
        }

        const subElements = selectedSubreport.resource.split('/');
        const subName = subElements[ subElements.length - 1 ].replaceAll('_', ' ');

        const subreportParamsData: subReportParamData[] = [];
        for (const i in selectedSubreport.parameters) {
            if (i !== '_report') {
                subreportParamsData.push({ label: i, value: selectedSubreport.parameters[i].join(', ') });
            }
        }

        const desc = (
            <React.Fragment>
                <TextContent>
                    <Text component={ TextVariants.h3 }>{ subName }</Text>
                </TextContent>
                <DescriptionList isHorizontal>
                    {
                        subreportParamsData.map(data => (
                            <DescriptionListGroup key={ data.label }>
                                <DescriptionListTerm>{ data.label }</DescriptionListTerm>
                                <DescriptionListDescription>{ data.value }</DescriptionListDescription>
                            </DescriptionListGroup>
                        ))
                    }
                </DescriptionList>

            </React.Fragment>
        );

        return  desc;
    }, [ selectedReport, selectedSubreport ]);

    const reportTitle = React.useMemo(()=>{
        if (selectedReport === undefined) {
            return 'Nombre Del Reporte';
        }

        return  selectedReport.name;
    }, [ selectedReport ]);

    React.useEffect(()=>{
        if (user?.info?.jasper_role.reports) {
            const reports = user.info.jasper_role.reports;
            const reportsFilter = reports.filter(report => report.key === '/report/' + id);

            if (reportsFilter.length > 0) {
                const originalReport = reportsFilter[0];
                const subreportFiltered = originalReport.sub_reports?.filter(subreport => subreport.id === subId);

                if (subreportFiltered && subreportFiltered.length > 0) {
                    const subreport = subreportFiltered[0];
                    setSelectedReport(originalReport);
                    setSelectedSubreport(subreport);
                } else {
                    addAlert(
                        'Acceso restringido',
                        'Tu usuario no tiene acceso al reporte pedido. Pongase en contácto con su admisnitrador',
                        'danger',
                        alertDispatch
                    );
                }
            } else {
                addAlert(
                    'Acceso restringido',
                    'Tu usuario no tiene acceso al reporte pedido. Pongase en contácto con su admisnitrador',
                    'danger',
                    alertDispatch
                );
            }
        }
    }, [ id, subId, user?.info?.jasper_role.reports, alertDispatch ]);

    const key = React.useMemo(()=>{
        if (report === undefined) {
            return '';
        }

        return report.key;
    }, [ report ]);

    return (
        <PageLayout title={ reportTitle } description={ description } dontForceInfo showAll={ showAll }>
            <DiazarReportContainer id="report-container"
                containerMessage="Área de Reporte"
                report={ report }
                showAll={ showAll }
                setShowAll={ setShowAll }
                reportParams={ reportParams }
                keyId={ key }
            />
        </PageLayout>
    );
};
