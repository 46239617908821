import React, { Key } from 'react';
import {
    Avatar, Brand,
    Button, ButtonVariant, Divider, Dropdown,
    DropdownGroup,
    DropdownItem, DropdownSeparator, DropdownToggle, KebabToggle, PageHeader,
    Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem
} from '@patternfly/react-core';
import { ExternalLinkAltIcon  } from '@patternfly/react-icons';
import imgAvatar from '@patternfly/react-styles/css/assets/images/img_avatar.svg';
import { big_logo, DiazarBadge, DiazarSecure } from '../../Config/Images';
import { useAuthDispatch, useAuthState } from '../../Context/AuthProvider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Context/AuthProvider/AuthProviderActions';
import { useAppsDispatch, useAppsState } from '../../Context/DiazarSuiteProvider/DiazarSuiteProvider';
import { getApps } from '../../Context/DiazarSuiteProvider/DiazarSuiteProviderActions';
import { DiazarSuitePopover } from '../DiazarSuitePopover';
import { APP_ID, COOKIE_NAME } from '../../Config/UrlRuotes';
import { useCookies } from 'react-cookie';

const keyDropDown = 'group-dropdown';

interface NavItemData {
    key?: Key | null | undefined;
    label: string;
    onClick?: () => void;
    icon?: React.ReactNode;
    isDisabled?: boolean;
}

export const DiazarPageHeader: React.FunctionComponent = (_props) => {
    const [ cookies, setCookie ] = useCookies([ COOKIE_NAME ]);

    const dispatch = useAuthDispatch();
    const userDetails = useAuthState();
    const navigate = useNavigate();

    const switeData = useAppsState();
    const appsDispatch = useAppsDispatch();

    /* --> Elementos del Dropdown usuarios <-- */
    const [ isDropdownOpen, setIsDropdownOpen ] = React.useState<boolean>(false);
    const onDropdownToggle = React.useCallback((isDropdownOpen:boolean)=>{
        setIsDropdownOpen(isDropdownOpen);
    }, []);
    const onDropdownSelect = React.useCallback(()=>{
        setIsDropdownOpen(prev => !prev);
    }, []);

    const removeCoockie = React.useCallback(()=>{
        let cookieObj = (cookies[COOKIE_NAME] !== undefined) ? cookies[COOKIE_NAME] : [];
        cookieObj = cookieObj.filter(val => val.id !== APP_ID);

        setCookie(COOKIE_NAME, cookieObj, { path: '/', domain: 'diazar.com' });
    }, [ cookies, setCookie ]);

    const handleLogout = React.useCallback(()=>{
        logout(dispatch);
        removeCoockie();
        navigate('/login');
    }, [ navigate, dispatch, removeCoockie ]);

    const infoData: NavItemData[]  = React.useMemo(() => {
        return [
            {
                key: 'privacy-policies',
                label: 'Politicas de Privacidad',
                icon: <DiazarBadge />,
                onClick: () => { navigate('/privacy-policies'); }
            },
            {
                key: 'terms-of-use',
                label: 'Terminos de Uso',
                icon: <DiazarSecure />,
                onClick: () => { navigate('/terms-of-use'); }
            }
        ];
    }, [ navigate ]);

    const userData = React.useMemo< NavItemData[] >(()=>{
        if (!userDetails.token) {
            return [];
        }

        const data:NavItemData[] = [];

        if (userDetails.user) {
            if (userDetails.user.info?.company_name) {
                data.push({ key: 'user-company', label: `Empresa:  ${ userDetails.user.info.company_name }`, isDisabled: true });
            }

            if (userDetails.user.info?.jasper_role.name) {
                data.push({ key: 'user-role', label: `Rol:  ${ userDetails.user.info.jasper_role.name }`, isDisabled: true });
            }

            if (userDetails.user.first_name &&  userDetails.user.last_name) {
                data.push({
                    key: 'user-key',
                    label: (userDetails.user.first_name + ' ' + userDetails.user.last_name).trim(), isDisabled: true }
                );
            }

            data.push({
                key: 'user-logout',
                label: 'Logout',
                onClick: handleLogout,
                icon: <ExternalLinkAltIcon />
            });
        }

        return data;
    }, [ userDetails, handleLogout ]);

    const getDropDownGroup = React.useCallback((keyStr:string)=>{
        return [
            <DropdownGroup key={ keyStr }>
                { userData.map(data => <DropdownItem key={ data.key } { ...data }> { data.label }</DropdownItem>) }
            </DropdownGroup>
        ];
    }, [ userData ]);

    const userDropdownItems = React.useMemo(() => {
        return getDropDownGroup(keyDropDown + '-1');
    }, [ getDropDownGroup ]);

    /* --> Elementos del Kebab <-- */
    const [ isKebabDropdownOpen, setIsKebabDropdownOpen ] = React.useState<boolean>(false);
    const [ isFullKebabDropdownOpen, setIsFullKebabDropdownOpen ] = React.useState<boolean>(false);

    const onKebabDropdownToggle = React.useCallback((isKebabDropdownOpen:boolean)=>{
        setIsKebabDropdownOpen(isKebabDropdownOpen);
    }, []);
    const onKebabDropdownSelect = React.useCallback(()=>{
        setIsKebabDropdownOpen(prev => !prev);
    }, []);

    const kebabDropdownItems = React.useMemo(() => {
        const el = infoData.map(data => <DropdownItem key={ data.key } { ...data }> { data.label }</DropdownItem>);

        if (switeData.apps !== undefined && switeData.apps.length > 0) {
            el.push(<DropdownSeparator key="separator" />);

            switeData.apps.map(app => {
                el.push((
                    <DropdownItem key={ `app-diazar-suite-${ app.id }` }
                        icon={ (<img src={ app.logo_url } alt={ app.name } />) }
                        href={ app.app_url }
                        target="_blank"
                    >
                        {app.name}
                    </DropdownItem>
                ));
                return true;
            });
        }

        return el;
    }, [ infoData, switeData.apps ]);

    const onFullKebabToggle = React.useCallback((isFullKebabDropdownOpen:boolean)=>{
        setIsFullKebabDropdownOpen(isFullKebabDropdownOpen);
    }, []);
    const onFullKebabSelect = React.useCallback(()=>{
        setIsFullKebabDropdownOpen(prev => !prev);
    }, []);
    const fullKebabItems = React.useMemo(() => {
        const  elements = getDropDownGroup(keyDropDown + '-2');
        elements.push(<Divider key="divider" />);

        for (let i = 0; i < kebabDropdownItems.length; i++) {
            elements.push(kebabDropdownItems[i]);
        }

        return elements;
    }, [ kebabDropdownItems, getDropDownGroup ]);

    const tollbarIcons  = React.useMemo(()=>{
        return (
            <ToolbarGroup variant="icon-button-group" visibility={ { default: 'hidden', lg: 'visible' } }>
                {
                    infoData.map(data => (
                        <ToolbarItem key={ data.key }>
                            <Button aria-label={ data.label  }
                                variant={ ButtonVariant.plain }
                                onClick={ data.onClick }
                            >
                                {  data.icon }
                            </Button>
                        </ToolbarItem>
                    ))
                }
                <DiazarSuitePopover />
            </ToolbarGroup>
        );
    }, [ infoData ]);

    const headerTools = React.useMemo(()=>{
        return (
            <Toolbar id="nav-main-toolbar" isFullHeight isStatic>
                <ToolbarContent>
                    <ToolbarGroup
                        variant="icon-button-group"
                        alignment={ {
                            default: 'alignRight',
                            md: 'alignRight',
                            lg: 'alignRight',
                            xl: 'alignRight',
                            '2xl': 'alignRight'
                        } }
                        spacer={ { default: 'spacerNone', md: 'spacerMd' } }
                    >
                        { tollbarIcons }
                        <ToolbarItem visibility={ { default: 'hidden', md: 'visible', lg: 'hidden' } }>
                            <Dropdown
                                isPlain
                                position="right"
                                onSelect={ onKebabDropdownSelect }
                                toggle={ <KebabToggle onToggle={ onKebabDropdownToggle } /> }
                                isOpen={ isKebabDropdownOpen }
                                dropdownItems={ kebabDropdownItems }
                            />
                        </ToolbarItem>
                        <ToolbarItem visibility={ { default: 'visible', md: 'hidden', lg: 'hidden', xl: 'hidden', '2xl': 'hidden' } }>
                            <Dropdown
                                isPlain
                                position="right"
                                onSelect={ onFullKebabSelect }
                                toggle={ <KebabToggle onToggle={ onFullKebabToggle } /> }
                                isOpen={ isFullKebabDropdownOpen }
                                dropdownItems={ fullKebabItems }
                            />
                        </ToolbarItem>
                    </ToolbarGroup>
                    <ToolbarItem visibility={ { default: 'hidden', md: 'visible' } } >
                        <Dropdown
                            position="right"
                            onSelect={ onDropdownSelect }
                            isOpen={ isDropdownOpen }
                            toggle={ <DropdownToggle
                                icon={ <Avatar src={ imgAvatar } alt="Avatar" /> }
                                onToggle={ onDropdownToggle }
                                toggleVariant="default"
                            >
                                { userDetails.user?.clave }
                            </DropdownToggle> }
                            dropdownItems={ userDropdownItems }
                        />
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
        );
    }, [
        fullKebabItems, isDropdownOpen, isFullKebabDropdownOpen, isKebabDropdownOpen, kebabDropdownItems, userDropdownItems,
        onDropdownSelect, onDropdownToggle, onFullKebabSelect, onFullKebabToggle, onKebabDropdownSelect, onKebabDropdownToggle,
        tollbarIcons, userDetails.user
    ]);

    React.useEffect(()=>{
        if (switeData.apps === undefined || switeData.apps.length === 0) {
            const token = (userDetails.token) ? userDetails.token : '';
            getApps(token, appsDispatch);
        }
    }, [ switeData.apps, userDetails.token, appsDispatch ]);

    return (
        <PageHeader logo={ <Brand className="brand-logo"
            src={ big_logo }
            alt="Diazar Reports LOGO" /> }
        headerTools={ headerTools }
        showNavToggle
        />
    );
};
