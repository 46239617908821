import React, { ReactNode } from 'react';
import {
    Bullseye,
    Button,
    Grid,
    GridItem,
    SimpleList,
    SimpleListItem,
    Tab,
    Tabs,
    TabTitleText
} from '@patternfly/react-core';
import { SelectViewProps } from './DiazarInputControlSelect';
import { ReportSelectOption } from '../../Context/AuthProvider/AuthDomain';
import { AiOutlineCheck, AiOutlineClose, AiOutlineSwap } from 'react-icons/ai';
import { TexInputFilter } from './TexInputFilter';

export const DiazarInputControlMultiSelect: React.FunctionComponent<SelectViewProps> = (props) => {
    const [ activeTabKey, setActiveTabKey ] = React.useState<number>(1);
    const [ values, setValues ] = React.useState<ReportSelectOption[]>([]);
    const [ filterValue, setFilterValue ] = React.useState<string>('');

    const handleTabClick = React.useCallback((event, tabIndex)=>{
        event.preventDefault();
        setActiveTabKey(tabIndex);
    }, []);

    const onSelect = React.useCallback((selectedItem:any, selectedItemProps:any)=>{
        const selection = selectedItemProps.itemId;
        let values:string[] = [];

        if (typeof props.selected === 'string') {
            values.push(props.selected);
        } else {
            values = props.selected;
        }

        if (values.includes(selection)) {
            values = values.filter(i => i !== selection);
        } else {
            values.push(selection);
        }

        props.onChange(props.inputData.id, values);
    }, [ props ]);

    const SelectedListData = React.useMemo(()=>{
        if (typeof props.selected !== 'string' && props.inputData.values) {
            const filtered = props.inputData.values.map(val => {
                let valObj:ReportSelectOption;
                if (typeof val === 'string') {
                    valObj = { id: val, value: val };
                } else {
                    valObj = { id: val.id, value: val.value };
                }

                return valObj;
            }).filter(val => props.selected.includes(val.id));

            return filtered.map(selected => <SimpleListItem itemId={ selected.id } key={ selected.id }>{ selected.value }</SimpleListItem>);
        }

        return [];
    }, [ props ]);

    const ListData = React.useMemo(()=>{
        console.log(values);
        if (props.inputData.values && props.inputData.values.length) {
            return values.map(data => {
                const isActive = (Array.isArray(props.selected)) ? props.selected.includes(data.id) : data.id === props.selected;

                return (
                    <SimpleListItem itemId={ data.id } isActive={ isActive } key={ data.id }>{ data.value }</SimpleListItem>
                );
            });
        }

        return null;

    }, [ props, values ]);

    const Buttons = React.useMemo(()=>{
        const btns : ReactNode[] = [];
        const selcctAllIsDisable = props.inputData.values?.length === props.selected.length;
        const btnSellectAll = (
            <Button
                variant="control"
                isDisabled={ selcctAllIsDisable }
                isBlock
                icon={ <AiOutlineCheck /> }
                onClick={ ()=>{
                    const values = props.inputData.values?.map(val => (typeof val === 'string') ? val : val.id);
                    props.onChange(props.inputData.id, values);
                } }
            >
                Seleccionar Todo
            </Button>
        );
        btns.push(btnSellectAll);

        const unselectAllIsDisable = props.selected.length === 0;
        const btnUnselectAll = (
            <Button
                variant="control"
                isBlock
                isDisabled={ unselectAllIsDisable }
                icon={ <AiOutlineClose /> }
                onClick={ ()=>{
                    const values = [];
                    props.onChange(props.inputData.id, values);
                } }
            >
                Ninguno
            </Button>
        );
        btns.push(btnUnselectAll);

        const invertSelectAll = (
            <Button
                variant="control"
                isBlock
                icon={ <AiOutlineSwap /> }
                onClick={ ()=>{
                    const values = props.inputData.values?.map(val => (typeof val === 'string') ? val : val.id)
                    .filter(val => !props.selected.includes(val));

                    props.onChange(props.inputData.id, values);
                } }
            >
                Invertir Selección
            </Button>
        );
        btns.push(invertSelectAll);

        return btns;
    }, [ props ]);

    const EmptiState = React.useMemo(()=>{
        return (
            <Bullseye>
                <div>No hay datos para mostrar</div>
            </Bullseye>
        );
    }, []);

    React.useEffect(()=>{
        const vals = (props.inputData.values) ? props.inputData.values.map(value => {
            if (typeof value === 'string') {
                return { id: value, value };
            } else {
                return value;
            }
        }) : [];

        if (filterValue === '') {
            setValues(vals);
        } else {
            const filteredValues = vals.filter(value => value.value.toLowerCase().includes(filterValue.toLowerCase()));
            setValues(filteredValues);
        }
    }, [ filterValue, props.inputData.values ]);

    return (
        <React.Fragment>
            <Tabs
                activeKey={ activeTabKey }
                onSelect={ handleTabClick }
                usePageInsets
                isBox
                isFilled
                role="tabpanel"
            >
                <Tab eventKey={ 1 } title={ <TabTitleText>Disponibles: { ListData?.length }</TabTitleText> }>
                    {
                        (ListData && ListData.length > 0) ?
                            (activeTabKey === 1) ? (
                                <SimpleList key={ 1 } isControlled={ false } onSelect={ onSelect } className="list-data">
                                    <SimpleListItem key={ -1 }>
                                        <TexInputFilter filterValue={ filterValue } setFilterValue={ setFilterValue } />
                                    </SimpleListItem>
                                    {  ListData }
                                </SimpleList>
                            ) : null
                            : (
                                <div className="list-data" >
                                    <div className="list-data-filter" >
                                        <TexInputFilter filterValue={ filterValue } setFilterValue={ setFilterValue } />
                                    </div>
                                    { EmptiState }
                                </div>
                            )
                    }
                </Tab>
                <Tab eventKey={ 2 } title={ <TabTitleText>Selecionados: { SelectedListData?.length }</TabTitleText> }>
                    {
                        (SelectedListData && SelectedListData.length > 0) ?
                            (activeTabKey === 2) ? (
                                <SimpleList key={ 1 } isControlled={ false } onSelect={ onSelect } className="list-data">
                                    { SelectedListData }
                                </SimpleList>
                            ) : null
                            : (
                                <div className="list-data" >
                                    { EmptiState }
                                </div>
                            )
                    }
                </Tab>
            </Tabs>

            <Grid>
                { Buttons.map((btn, index) => (
                    <GridItem span={ 4 } key={ index }>{ btn }</GridItem>
                )) }
            </Grid>

        </React.Fragment>
    );
};
