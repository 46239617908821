import React from 'react';
import { DiazarSuiteDispacher, DiazarSuiteResponse } from './DiazarSuiteProviderDomain';
import { ServicesUrl } from '../../Config/UrlRuotes';

export async function getApps(token: string, dispatch:React.Dispatch<DiazarSuiteDispacher>) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization-Token': token
        }
    };

    try {
        dispatch({ type: 'REQUEST' });
        const response = await fetch(ServicesUrl.apps, requestOptions);

        const data : DiazarSuiteResponse = await response.json();
        if (data.error['has-error']) {
            dispatch({ type: 'ERROR', payload: { errorMessage: data.error.message }});
            return;
        } else {
            dispatch({ type: 'SUCCESS', payload: { apps: data.response }});
            return;
        }
    } catch (error) {
        const message = 'Error al momento de realizar la petición';
        dispatch({ type: 'ERROR', payload: { errorMessage: message }});

        return;
    }
}
