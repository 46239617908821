import React from 'react';
import { DiazarSuiteValidToken } from './Login';
import {
    Button,
    Grid,
    GridItem,
    Modal,
    ModalVariant,
    Panel,
    PanelMainBody
} from '@patternfly/react-core';
import styles  from './DiazarSuiteModal.module.css';

interface ViewProps{
    isModalOpen: boolean;
    setIsModalOpen: (boolean)=>void;
    items: DiazarSuiteValidToken[]
    onClickItem: (item:DiazarSuiteValidToken) => void;
}

export const DiazarSuiteTokenModal : React.FunctionComponent<ViewProps> = (props)=>{
    const [ selectedItem, setSelectedItem ] = React.useState<DiazarSuiteValidToken|undefined>(undefined);

    const handleModalToggle = React.useCallback(()=>{
        props.setIsModalOpen(prev => !prev);
    }, [ props ]);

    const handleSendModalToggle = React.useCallback(async ()=>{
        if (selectedItem !== undefined) {
            props.setIsModalOpen(prev => !prev);
            props.onClickItem(selectedItem);
        }
    }, [ props, selectedItem ]);

    const items = React.useMemo(()=>{
        return props.items.map((item, index) => {
            const className = (selectedItem && selectedItem.token === item.token) ? styles.panelSuiteActive : styles.panelSuite;
            return (
                <GridItem onClick={ ()=>{ setSelectedItem(prevState => {
                    if (prevState && prevState.token === item.token) {
                        return undefined;
                    }

                    return item;
                }); } }
                key={ `grid-item-${ index }` }>
                    <Panel className={ className } variant="raised">
                        <PanelMainBody>
                            <h1>{ `${item.name} (${ item.key })` }</h1>
                            {item.company}
                        </PanelMainBody>
                    </Panel>
                </GridItem>
            );
        });
    }, [ props.items, selectedItem ]);

    return (
        <Modal
            variant={ ModalVariant.small }
            title="Diazar Suite"
            isOpen={ props.isModalOpen }
            onClose={ handleModalToggle }
            actions={ [
                <Button key="send"
                    variant="primary"
                    onClick={ handleSendModalToggle }
                    isDisabled={ (selectedItem === undefined) }
                >
                    Enviar
                </Button>,
                <Button key="cancel" variant="danger" onClick={ handleModalToggle }>
                    Cancelar
                </Button>
            ] }
        >
            <p>
                Se ha detectado que existen sesiones abiertas de Diazar Suite:
            </p>
            <p>
                Puede iniciar sesión seleccionando alguna sesión activa o dando clic a &ldquo;Cancelar&rdquo;
                para ingresar normalmente
            </p>
            <br />
            <Grid hasGutter span={ 6 }>
                {items}
            </Grid>
        </Modal>
    );
};
