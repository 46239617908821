import React from 'react';
import { ServicesUrl } from '../../Config/UrlRuotes';
import moment from 'moment/moment';
import { now } from 'moment';
import {
    InfoActionResponse,
    LoginActionResponse,
    LoginDispacher,
    LoginPayloadRequest,
    UserAppInfo, UserAppReportPair
} from './AuthDomain';
import { visualizeHelper } from '../../Components/VisualizeHelper';
import { LoginPayload } from './AuthProviderReducer';

export async function checkLogginJasper(data:UserAppInfo) {
    await visualizeHelper.login(data.jasper_user.username, data.jasper_user.password);
}

export async function getInfoUser(dispatch:React.Dispatch<LoginDispacher>, token:string) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization-Token': token }
    };

    try {
        dispatch({ type: 'REQUEST_INFO' });
        const response = await fetch(ServicesUrl.info, requestOptions);
        const data : InfoActionResponse = await response.json();

        if (data.status === 'ERROR' && data.error['has-error']) {
            dispatch({ type: 'INFO_ERROR', error: data.error.error });
            return data;
        }

        if (data.response.jasper_role.id > 0) {
            data.response.jasper_role.reports.map((report, index) => {
                const longUri = report.uri;
                const elements = longUri.split('/');

                data.response.jasper_role.reports[ index ].key = '/report/' + elements[ elements.length - 1 ];
                return true;
            });

            const appData = localStorage.getItem('currentUser')
                ? JSON.parse(localStorage.getItem('currentUser') + '')
                : {};

            const user = appData.user;
            user.info = data.response;

            localStorage.setItem('currentUser', JSON.stringify(appData));

            dispatch({ type: 'INFO_SUCCESS', payload: { user }});
        }

        return;
    } catch (error) {
        const message = 'Error al momento de realizar la petición';
        dispatch({ type: 'LOGIN_ERROR', error: message });

        return;
    }
}

export async function validateToken(token:string, dispatch:React.Dispatch<LoginDispacher>) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        const response = await fetch(`${ ServicesUrl.login }/${ token }`, requestOptions);

        const data : LoginActionResponse = await response.json();

        if (data.status === 'ERROR' && data.error['has-error']) {
            dispatch({ type: 'LOGIN_ERROR', error: data.error.error });
            return;
        }

        if (data.response.token) {
            data.response.user.date = moment(now());
            dispatch({ type: 'LOGIN_SUCCESS', payload: data.response });
            localStorage.setItem('currentUser', JSON.stringify(data.response));
            return data;
        }

        return;
    } catch (error) {
        const message = 'Error al momento de realizar la petición';
        dispatch({ type: 'LOGIN_ERROR', error: message });

        return;
    }
}

export async function loginUser(loginPayload:LoginPayloadRequest, dispatch:React.Dispatch<LoginDispacher>) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload)
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        const response = await fetch(ServicesUrl.login, requestOptions);

        const data : LoginActionResponse = await response.json();

        if (data.status === 'ERROR' && data.error['has-error']) {
            dispatch({ type: 'LOGIN_ERROR', error: data.error.error });
            return;
        }

        if (data.response.token) {
            data.response.user.date = moment(now());
            dispatch({ type: 'LOGIN_SUCCESS', payload: data.response });
            localStorage.setItem('currentUser', JSON.stringify(data.response));
            return data;
        }

        return;
    } catch (error) {
        const message = 'Error al momento de realizar la petición';
        dispatch({ type: 'LOGIN_ERROR', error: message });

        return;
    }
}

export async function logout(dispatch:React.Dispatch<LoginDispacher>) {
    dispatch({ type: 'LOGOUT' });
    await visualizeHelper.logOut();

    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}

export function setSubReports(uri:string, data:UserAppReportPair[], dispatch:React.Dispatch<LoginDispacher>) {
    const payload : LoginPayload = {
        user: { id: 0,  clave: '', first_name: '', last_name: '', user_type: '', date: moment(),
            info: {
                company_name: '',
                jasper_user: { id: 0, password: '',  username: '',  company_id: 0 },
                jasper_role: { id: 0,  name: '',
                    reports: [
                        // eslint-disable-next-line max-len
                        { id: '', name: '', key: '', uri, description: '', label: '', input_controls: [], sub_reports: data, is_adhoc: false, force_get_inputs: false }
                    ]
                }
            }
        }
    };

    dispatch({ type: 'REMOVE_SUB_REPORTS', payload });
}

export function addSubReports(uri:string, data:UserAppReportPair, dispatch:React.Dispatch<LoginDispacher>) {
    data.active = true;

    const payload = localStorage.getItem('currentUser')
        ? JSON.parse(localStorage.getItem('currentUser') + '')
        : {};

    if (payload.user.info) {
        const reports = payload.user.info.jasper_role.reports;
        reports.filter(report => report.uri === uri)
        .map(report => {
            if (report.sub_reports === undefined) {
                report.sub_reports = [ data ];
            } else {
                report.sub_reports.push(data);
            }

            return true;
        });
    }

    localStorage.setItem('currentUser', JSON.stringify(payload));

    dispatch({ type: 'REMOVE_SUB_REPORTS', payload });
}
