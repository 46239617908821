import React from 'react';
import { PageLayout } from '../../Layouts/PageLayout';
import { UserAppReport } from '../../Context/AuthProvider/AuthDomain';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from '../../Context/AuthProvider/AuthProvider';
import { addAlert } from '../../Context/AlertProvider/AlertProviderActions';
import { useAlertDispatcherState } from '../../Context/AlertProvider/AlertProvider';
import { DiazarReportContainer } from '../../Components/Reports/DiazarReportContainer';

export const Report: React.FunctionComponent = (_props) => {
    const [ selectedReport, setSelectedReport ] = React.useState<UserAppReport|undefined>(undefined);
    const [ reportMessage ] = React.useState<string>('Área de Reporte');

    const [ showAll, setShowAll ] = React.useState<boolean>(false);

    const { user } = useAuthState();
    const { id } = useParams();

    const alertDispatch = useAlertDispatcherState();
    const navigate = useNavigate();

    const description = React.useMemo(()=>{
        if (selectedReport === undefined) {
            return 'Descripción del Reporte';
        }

        return selectedReport.description;
    }, [ selectedReport ]);

    const reportTitle = React.useMemo(()=>{
        if (selectedReport === undefined) {
            return 'Nombre Del Reporte';
        }

        return  selectedReport.name;

        /*
        const Btn = <Button className="icon-btn icon-btn-title" onClick={ ()=>{ setShowModalParams( true ) } }><BiWindowOpen /></Button>
        return (
            <React.Fragment>
                { Btn }{' '}{ selectedReport.name }
            </React.Fragment>
        )
        */

    }, [ selectedReport ]);

    React.useEffect(()=>{
        if (user?.info?.jasper_role.reports) {
            const reports = user.info.jasper_role.reports;
            const reportsFilter = reports.filter(report => report.key === '/report/' + id);

            if (reportsFilter.length > 0) {
                const report = reportsFilter[0];
                setSelectedReport(report);
            } else {
                addAlert(
                    'Acceso restringido',
                    'Tu usuario no tiene acceso al reporte pedido. Pongase en contácto con su admisnitrador',
                    'danger',
                    alertDispatch
                );

                navigate('/');
            }
        }
    }, [ id, user?.info?.jasper_role.reports, alertDispatch, navigate ]);

    const key = React.useMemo(()=>{
        if (selectedReport === undefined) {
            return '';
        }

        return selectedReport.key;
    }, [ selectedReport ]);

    return (
        <PageLayout title={ reportTitle } description={ description } showAll={ showAll }>
            <DiazarReportContainer id="report-container"
                containerMessage={ reportMessage }
                report={ selectedReport }
                showAll={ showAll }
                setShowAll={ setShowAll }
                keyId={ key }
            />
        </PageLayout>
    );
};
