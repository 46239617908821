import React from 'react';
import {
    Bullseye,
    Nav,
    NavExpandable,
    NavGroup,
    NavItem,
    NavItemSeparator,
    PageSidebar,
    Spinner
} from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../Context/AuthProvider/AuthProvider';
import { useLocation } from 'react-router-dom';
import { CreativeCommonsShareIcon } from '@patternfly/react-icons/dist/esm/icons';

const DiazarNav: React.FunctionComponent = (_props) => {
    const { loading, user } = useAuthState();
    const navigate = useNavigate();
    const location = useLocation();

    const [ activeItem, setActiveItem ] = React.useState<string>('/');
    const [ activeGroup, setActiveGroup ] = React.useState<string>('/');

    const companyName = React.useMemo(()=>{
        return (user?.info?.company_name) ? user.info.company_name : '';
    }, [ user?.info?.company_name ]);

    const NavCompany = React.useMemo(() => (
        <NavGroup title={  `Empresa: ${ companyName }` } aria-label="Main">
            <NavItem itemId={ '/' } isActive={ activeItem === '/' } onClick={ ()=>{ navigate('/'); } }  >
                Inicio
            </NavItem>
        </NavGroup>
    ), [ companyName, activeItem, navigate ]);

    const reports = React.useMemo(()=>{
        return (user?.info?.jasper_role.reports) ? user.info.jasper_role.reports : [];
    }, [ user?.info?.jasper_role.reports ]);

    const ReportsItems = reports.map(report => {
        const Icon = (report.is_adhoc) ? <CreativeCommonsShareIcon style={ { marginRight: '10px' } }/> :  null;
        if (report.sub_reports === undefined || report.sub_reports.length === 0) {
            return (
                <NavItem key={ report.id }
                    itemId={ report.key }
                    isActive={ activeItem === report.key }
                    onClick={ () => {
                        navigate(report.key);
                    } }
                >
                    { Icon } {report.name}
                </NavItem>
            );
        }

        const Items = [
            <NavItem key={ report.id }
                itemId={ report.key }
                isActive={ activeItem === report.key }
                onClick={ () => {
                    navigate(report.key);
                } }
            >
                    Reporte Principal
            </NavItem>,
            <NavItemSeparator key={ report.id + '-separator' } />
        ];

        report.sub_reports.map(subreport => {
            const key = `/sub-report/${ report.key.replace('/report/', '') }/${subreport.id}`;
            const elements = subreport.resource.split('/');
            let name = elements[elements.length - 1].replaceAll('_', ' ');

            const parametersData : string[] = [];
            for (const i in subreport.parameters) {
                if (i !== '_report') {
                    parametersData.push(i + ': ' + subreport.parameters[i].join(', '));
                }
            }

            if (parametersData.length > 0) {
                name += ': ';
                name += parametersData.join(', ');
            }

            Items.push((
                <NavItem key={ subreport.id }
                    itemId={ key }
                    isActive={ activeItem === key }
                    onClick={ () => {
                        navigate(key);
                    } }
                >
                    { Icon } {name}
                </NavItem>
            ));

            return true;
        });

        return (
            <NavExpandable title={ report.name }
                groupId={ report.key }
                isExpanded={ false }
                isActive={ activeGroup === report.key }
                key={ report.id }
            >
                { Items }
            </NavExpandable>
        );
    });

    React.useEffect(()=>{
        const elements = location.pathname.split('/');

        if (elements[1] === 'sub-report') {
            setActiveGroup(`/report/${ elements[2] }`);
        }

        setActiveItem(location.pathname);
    }, [ location.pathname ]);

    if (loading) {
        return  (
            <Bullseye style={ { marginTop: '20px' } }>
                <Spinner aria-label="Cargando Información"/>
            </Bullseye>
        );
    }

    if (user?.info === undefined) {
        return null;
    }

    return (
        <Nav aria-label="Nav" key="diazar-nav">
            { NavCompany }
            <NavGroup title={ 'Reportes' } aria-label="Reportes:">
                { ReportsItems }
            </NavGroup>
        </Nav>
    );
};

export const DiazarSideBar: React.FunctionComponent = (_props) => {
    const PageNav = <DiazarNav />;
    return (
        <PageSidebar nav={ PageNav } />
    );
};
