import React from 'react';
import {
    BackToTop,
    Page,
    PageSection,
    PageSectionVariants,
    SkipToContent,
    TextContent
} from '@patternfly/react-core';
import { DiazarPageHeader } from '../Components/PageHeader/DiazarPageHeader';
import { DiazarAlerts } from '../Components/DiazarAlerts';
import { DiazarSideBar } from '../Components/PageHeader/DiazarSideBar';
import { useAuthDispatch, useAuthState } from '../Context/AuthProvider/AuthProvider';
import { useAlertDispatcherState } from '../Context/AlertProvider/AlertProvider';
import { getInfoUser, logout } from '../Context/AuthProvider/AuthProviderActions';
import { addAlert } from '../Context/AlertProvider/AlertProviderActions';

interface ViewProps{
    title: string | React.ReactNode;
    dontForceInfo?: boolean
    children?: React.ReactNode;
    description?:React.ReactNode;
    showAll?: boolean
}
const pageId = 'main-content-page-layout-tertiary-nav';

export const PageLayout: React.FunctionComponent<ViewProps> = (props) => {
    const PageSkipToContent = <SkipToContent href={ `#${pageId}` }>Skip to content</SkipToContent>;
    const [ isBackToTopVisible, setIsBackToTopVisible ] = React.useState<boolean>(false);

    const onScroll = React.useCallback((e:any) => {
        setIsBackToTopVisible((e.target as HTMLDivElement).scrollTop > 100);
    }, []);

    const dispatch = useAuthDispatch();
    const alertDispatch = useAlertDispatcherState();
    const { errorMessage, token, user } = useAuthState();

    const forceInfo = React.useMemo(()=> (props.dontForceInfo) ? !props.dontForceInfo : true, [ props.dontForceInfo ]);

    const effect = React.useCallback(async () =>  {
        if (user === undefined || user.info === undefined || forceInfo) {
            try {
                if (token !== undefined && token.trim() !== '') {
                    await getInfoUser(dispatch, token);
                }
            } catch (error) {
                console.log(error);
            }
        }

    }, [ dispatch, token, user, forceInfo ]);

    React.useEffect(() => {
        if (errorMessage !== undefined && errorMessage !== '') {
            addAlert('Error', errorMessage, 'danger', alertDispatch);

            setTimeout(()=>{
                logout(dispatch).then();
            }, 500);
        }
    }, [ errorMessage, alertDispatch, dispatch ]);

    React.useEffect(()=>{
        effect().then();
    }, [ effect ]);

    return (
        <React.Fragment>
            <DiazarAlerts />
            <Page
                header={ <DiazarPageHeader /> }
                sidebar={ <DiazarSideBar /> }
                isManagedSidebar
                isTertiaryNavWidthLimited
                skipToContent={ PageSkipToContent }
                mainContainerId={ pageId }
                additionalGroupedContent={ (props.showAll) ? null :
                    <PageSection variant={ PageSectionVariants.light }>
                        <TextContent>
                            <h1>{ props.title }</h1>
                            { props.description }
                        </TextContent>
                    </PageSection> }
                groupProps={ {
                    sticky: 'top'
                } }
            >
                <PageSection hasOverflowScroll
                    name="scrolling-section"
                    tabIndex={ 0 }
                    onScroll={ onScroll }
                >
                    <div className="scrolling-container" style={ { padding: 0 } }>
                        { props.children }
                    </div>
                </PageSection>
                <BackToTop title="Volver arriba" scrollableSelector='[name="scrolling-section"]' isAlwaysVisible={ isBackToTopVisible }/>
            </Page>
        </React.Fragment>
    );
};
