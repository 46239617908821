import React from 'react';
import { Button, TextInputGroup, TextInputGroupMain, TextInputGroupUtilities } from '@patternfly/react-core';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';

interface ViewProps {
    filterValue:string
    setFilterValue:React.Dispatch<React.SetStateAction<string>>
}

export const TexInputFilter:React.FunctionComponent<ViewProps> = (props)=>{
    const [ showUtilities, setShowUtilities ] = React.useState<boolean>(false);
    const textInputGroupRef = React.useRef<HTMLDivElement>(null);

    const clear = React.useCallback(()=>{
        props.setFilterValue('');

        const input = textInputGroupRef.current?.querySelector('input');
        if (props.filterValue.length !== 0 && input) {
            input.focus();
        }
    }, [ props ]);

    React.useEffect(()=>{
        setShowUtilities(props.filterValue.length !== 0);
    }, [ props.filterValue ]);

    React.useEffect(()=>{
        const input = textInputGroupRef.current?.querySelector('input');
        if (props.filterValue.length !== 0 && input) {
            input.focus();
        }
    }, [ props.filterValue ]);

    return (
        <div ref={ textInputGroupRef }>
            <TextInputGroup>
                <TextInputGroupMain
                    icon={  <AiOutlineSearch /> }
                    value={ props.filterValue }
                    onChange={ (value)=>{ props.setFilterValue(value); } }
                    placeholder="Filtrar valores"
                />
                {showUtilities && (
                    <TextInputGroupUtilities>
                        {showUtilities && (
                            <Button onClick={ clear } variant="plain"  aria-label="Clear button and input">
                                <AiOutlineClose />
                            </Button>
                        )}
                    </TextInputGroupUtilities>
                )}
            </TextInputGroup>
        </div>
    );
};
